const handleAuthError = (error,email) => {
  switch (error.code) {
    case "auth/email-already-in-use":
      return `Email address ${email} is already in use.`;
    case "auth/invalid-email":
      return `Email address ${email} is invalid.`;
    case "auth/operation-not-allowed":
      return `Error during sign up.`;
    case "auth/weak-password":
      return "Password is not strong enough. Add additional characters including special characters and numbers.";
    default:
      return error.message;
  }
};
export { handleAuthError };
