import React from "react";
import Navbar from "../../components/Navbar/Navbar";
import './Clients.css';
import OurClientsSlider from "./slides/Slider";
import caalfrn from "../../../assets/Images/clients/caalfrn.png";
import aitutor from "../../../assets/Images/clients/aitutor.png";
import cwqqa7te from "../../../assets/Images/clients/cwqqa7te.png";
import easycontract from "../../../assets/Images/clients/easycontract.png";
import echoai from "../../../assets/Images/clients/echoai.png";
import flend from "../../../assets/Images/clients/flend.png";
import logoring from "../../../assets/Images/clients/logoring.png";
import optiweb from "../../../assets/Images/clients/optiweb.jpg";
import unnamed from "../../../assets/Images/clients/unnamed.png";
import wizilink from "../../../assets/Images/clients/wizilink.png";


const OurClients = ({showText=true}) => {
const slides = [ 
  {image:caalfrn},
{image:aitutor},
{image: cwqqa7te},
{image: easycontract},
{image: echoai},
{image: flend},
{image: logoring},
{image: optiweb},
{image: unnamed},
{image: wizilink},

]




  return (
    <div>
     
      <div className="spark-clients-slider">
        <section id="spark-clients" className="section-bg">
          <div className="container">
            {<div className={`${showText?"spark-client-section-header":"spark-client-section-header2"}`}>
              <h1
               
              >Our Clients</h1>
              <p>Meet our happy clients</p>
            </div>}

            <OurClientsSlider OurSlides={slides} aiSliderImages={false}/>
         
          </div>
        </section>
      </div>
    </div>
  );
};

export default OurClients;
