import React, { useState } from "react";
import "../card/Card.css";
import { MdDelete } from "react-icons/md";
import moment from "moment";
import { deleteDoc, doc } from "firebase/firestore";
import { database, Storage } from "../../firebase";
import toast from "react-hot-toast";
import Spinner from "../../pages/components/spiner/spiner";
import { deleteObject, ref } from "firebase/storage";
const ResumesCard = ({ email, resumeLink, date, name, index,professionType, fileName,getAllResumes }) => {
  const [loading, setLoading] = useState(false);
  const seconds = date.seconds;
  const postedDate = moment.unix(seconds).format("LLL");


  const deleteResume = async (_resumeId,_fileName,_name) => {
    try {
      setLoading(true);
      const storageRef = ref(Storage, `resumes/${fileName}`);
      await deleteObject(storageRef);
      await deleteDoc(doc(database, "resumes", _resumeId));
      setTimeout(async () => {
        await getAllResumes();
        setLoading(false);
      }, 1000);
      toast.success("Resume Delete successfully.");
    } catch (error) {
      toast.error(error.message);
      setLoading(false);
    }
  };

  

  if (loading) {
    return (
      <div>
        <Spinner />
      </div>
    );
  }



  return (
    <div className="spark-job-card">
      <div
        className="row"
       
      >
        <div className="col-sm-12 col-md-4 m-auto">
          <div className="card-header">{name}</div>
          <div className="card-body">
            <div className="job-salary-info">{email}</div>
            <div className="job-date-info">{professionType?professionType :""}</div>
            <div className="job-date-info">{postedDate ? postedDate : ""}</div>
          </div>
        </div>
        <div className="col-sm-12 col-md-4 m-auto">
          <div className="card-header-job-type">
            <div>
              <a
                style={{
                  color: "#284F8D",
                  textDecoration: "none",
                  fontWeight: "bold",
                  fontSize: "0.8rem",
                  cursor: "pointer",
                }}
                href={resumeLink}
                target="_blank"
                rel="noreferrer"
              >
                Resume Link
              </a>
            </div>
          </div>
        </div>
        <div className="col-sm-12 col-md-4 m-auto">
          <div className="card-body">
            <div
              style={{
                display: "flex",
                justifyContent: "space-around",
                alignItems: "center",
                width: "100%",
                height: "100%",
              }}
            >
              <div
                style={{
                  display: "flex",
                }}
              >
                <button
                  onClick={() => deleteResume(index, fileName,name)}
                  style={{
                    margin: ".5rem",
                    backgroundColor: "#284F8D",
                    color: "white",
                  }}
                  type="button"
                  className="btn btn-outline-primary "
                >
                  <MdDelete />
                </button>
              </div>
            </div>
          </div>
        </div>
        <div>
        </div>
      </div>
    </div>
  );
};

export default ResumesCard;
