import React from "react";
import "./OurProcess.css";
import {
  faArrowRight,
  faPhone,
  faShuffle,
  faUserGroup,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";
import { useStateContext } from "../../../context/AppContext";

export default function OurProcess() {
  const { dark } = useStateContext();
  const navigate = useNavigate();
  return (
    <div className="ourprocess-section">
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-6 col-md-12">
            <div className="d-flex justify-content-center">
              <h1>
                Our process. Simple, <br /> seamless, streamlined.
              </h1>
            </div>
            <img
              alt="Loading"
              src="https://bairesdev.mo.cloudinary.net/coresite/our_process.png"
              className="op-left-img"
              
            />
          </div>

          <div className="col-lg-6 col-md-12">
            <div className="op-sec-right">
              <div className="op-section-wrap">
                <div className="op-sec-line" />

                <div className="op-section-box">
                  <div className="op-box-icon">
                    <div
                      className="op-icon-div"
                      style={{ backgroundColor: dark }}
                    >
                      <FontAwesomeIcon
                        icon={faPhone}
                        style={{ color: "white" }}
                      />
                    </div>
                  </div>
                  <div className="op-sec-details">
                    <span>STEP 1</span>
                    <h3>Join exploration call.</h3>
                    <p>
                      Tell us more about your business on a discovery call.
                      We’ll discuss team structure and approach, success
                      criteria, timescale, budget, and required skill sets to
                      see how we can help.
                    </p>
                  </div>
                </div>

                <div className="op-section-box">
                  <div className="op-box-icon">
                    <div
                      className="op-icon-div"
                      style={{ backgroundColor: dark }}
                    >
                      <FontAwesomeIcon
                        icon={faUserGroup}
                        style={{ color: "white" }}
                      />
                    </div>
                  </div>
                  <div className="op-sec-details">
                    <span>STEP 2</span>
                    <h3>Discuss solution and team structure.</h3>
                    <p>
                      In a matter of days, we will finalize your project
                      specifications, agree on an engagement model, select and
                      onboard your team.
                    </p>
                  </div>
                </div>

                <div className="op-section-box">
                  <div className="op-box-icon">
                    <div
                      className="op-icon-div"
                      style={{ backgroundColor: dark }}
                    >
                      <FontAwesomeIcon
                        icon={faShuffle}
                        style={{ color: "white", rotate: "270deg" }}
                      />
                    </div>
                  </div>
                  <div className="op-sec-details">
                    <span>STEP 3</span>
                    <h3>Get started and track performance.</h3>
                    <p>
                      Once we’ve agreed on milestones, we’ll immediately get to
                      work. We’ll track progress, report updates, and
                      continuously adapt to your needs.
                    </p>
                  </div>
                </div>

                <div style={{ position: "relative" }}>
                  <div className="op-sec-line2" />
                  <div className="op-btn-div">
                    <button
                      className="op-button"
                      onClick={() => navigate("/schedule-call")}
                      style={{ backgroundColor: dark, color: "white" }}
                    >
                      Schedule a Call <FontAwesomeIcon icon={faArrowRight} />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
