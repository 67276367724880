import React, { useState } from "react";
import TextEditor from "../../components/TextEditor";
import CustomSelect from "../../components/Select";
import "./AddJobs.css";
import {
  CareerLevel,
  EducationLevels,
  Experience,
  JobType,
  Location,
  Profession,
  SalaryExpected,
  TotalPositions,
} from "../../utils/Const";
import {
  addDoc,
  collection,
  doc,
  updateDoc,
} from "firebase/firestore";
import { auth, database } from "../../firebase";
import toast from "react-hot-toast";
import moment from "moment";
const Admin = ({ uid }) => {
  const user = auth.currentUser;
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    profession: "",
    location: "",
    salaryExpected: "",
    yearsOfExperience: "",
    education: "",
    careerLevel: "",
    totalPositions: "",
    jobType: "",
    editor: "",
    postDate: "",
    applyDate: "",
  });

  const handleChange = (e) => {
    if (e.target) {
      const { name, value } = e.target;
      setFormData({ ...formData, [name]: value });
    } else if (e.name) {
      setFormData({ ...formData, [e.name]: e.value });
    } else {
      setFormData({ ...formData, editor: e });
    }
  };

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      if (uid === null) return toast.error("You currently have no user");
      for (const key in formData) {
        if (key !== "postDate" && !formData[key]) {
          toast.error("Please fill in all fields");
          return;
        }
      }
      if (!formData.postDate) {
        const currentDate = moment().format('YYYY-MM-DDTHH:mm');
        formData.postDate = currentDate;
      }
      setLoading(true);
      const docRef = await addDoc(collection(database, "jobs"), formData);
      const jobId = docRef.id;
      await updateDoc(doc(database, "jobs", jobId), { id: jobId });
      toast.success("Job added successfully");
      setLoading(false);
    } catch (e) {
      setLoading(false);
      console.log(e, "e");
      console.log(e);
    }
  };


 
  return (
    <div>
      <div className="sparkadmin-dashboard-job-admin-container">
        <div className="flex flex-col items-center justify-center">
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
          </div>
          <h1 className="text-heading mb-2 text-4xl font-bold text-center">
            Upload
            <span style={{ color: "rgb(27, 79, 146)" }}>
              {" "}
              Sparkix Technologies{" "}
            </span>
            <span> Jobs</span>
          </h1>
          <p className="text-lg text-center text-paragraph ">
            Admins alone have the power to upload jobs here, ensuring smooth
            operation.
          </p>
        </div>
        <div className="container mt-5">
          <div className="">
            <div className="col-md-12">
              <div className="card">
                <div className="card-header">
                  {" "}
                  Upload your job details here.
                </div>
                <div className="card-body">
                  <form onSubmit={handleSubmit}>
                    <div className="row pt-1">
                      <div className="col-sm-12 col-md-6">
                        <div className="form-group">
                          <CustomSelect
                            isFormData={true}
                            formData={formData}
                            name="Profession"
                            selectName="profession"
                            options={Profession}
                            value={formData.profession}
                            setFormData={setFormData}
                          />
                        </div>
                      </div>
                      <div className="col-sm-12 col-md-6">
                        <div className="form-group">
                          <CustomSelect
                            isFormData={true}
                            formData={formData}
                            name="Location"
                            selectName="location"
                            value={formData.location}
                            setFormData={setFormData}
                            options={Location}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row pt-1">
                      <div className="col-sm-12 col-md-6">
                        <div className="form-group">
                          <CustomSelect
                            isFormData={true}
                            formData={formData}
                            name="Salary Expected"
                            selectName="salaryExpected"
                            value={formData.salaryExpected}
                            setFormData={setFormData}
                            options={SalaryExpected}
                          />
                        </div>
                      </div>
                      <div className="col-sm-12 col-md-6">
                        <div className="form-group">
                          <div className="form-group">
                            <CustomSelect
                              isFormData={true}
                              formData={formData}
                              selectName="yearsOfExperience"
                              value={formData.yearsOfExperience}
                              name="Years Of Experience"
                              options={Experience}
                              setFormData={setFormData}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row pt-1">
                      <div className="col-sm-12 col-md-6">
                        <div className="form-group">
                          <CustomSelect
                            isFormData={true}
                            formData={formData}
                            selectName="education"
                            value={formData.education}
                            name="Education"
                            options={EducationLevels}
                            setFormData={setFormData}
                          />
                        </div>
                      </div>

                      <div className="col-sm-12 col-md-6">
                        <div className="form-group">
                          <CustomSelect
                            isFormData={true}
                            formData={formData}
                            selectName="careerLevel"
                            name="Career Level"
                            options={CareerLevel}
                            value={formData.careerLevel}
                            setFormData={setFormData}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="row pt-1">
                      <div className="col-sm-12 col-md-6">
                        <div className="form-group">
                          <CustomSelect
                            isFormData={true}
                            selectName="jobType"
                            name="Job Type"
                            value={formData.jobType}
                            options={JobType}
                            formData={formData}
                            setFormData={setFormData}
                          />
                        </div>
                      </div>
                      <div className="col-sm-12 col-md-6">
                        <div className="form-group">
                          <CustomSelect
                            isFormData={true}
                            selectName="totalPositions"
                            name="Total Positions"
                            value={formData.totalPositions}
                            options={TotalPositions}
                            formData={formData}
                            setFormData={setFormData}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="row pt-1">
                      <div className="col-sm-12 col-md-6">
                        <div className="form-group">
                          <div className="select-heading">Posted Date<span style={{
                            fontWeight: 400,
                            fontSize:"13px"
                          }}>(Optional)</span></div>
                          <input
                            type="datetime-local"
                            placeholder="Bachelor's in (BSCS / BSIT / BSSE)"
                            className="form-control"
                            name="postDate"
                            value={formData.postDate}
                            onChange={handleChange}
                           
                          />
                        </div>
                      </div>
                      <div className="col-sm-12 col-md-6">
                        <div className="form-group">
                          <div className="select-heading">Last Date to Apply</div>
                          <input
                            type="datetime-local"
                            id="datetime"
                            placeholder=""
                            className="form-control"  
                            name="applyDate"
                            value={formData.applyDate}
                            onChange={handleChange}
                            required
                          />
                        </div>
                      </div>
                    </div>

                    <div className="select-heading">Job Description</div>
                    <div
                      style={{
                        height: "400px",
                        width: "100%",
                      }}
                    >
                      <TextEditor
                        value={formData.editor}
                        handleChange={handleChange}
                      />
                    </div>
                    <div className="job-submit-container">
                      <button
                        disabled={loading}
                        type="submit"
                        className="job-submit-button btn btn-primary"
                      >
                        {loading ? "loading.." : "Save Job"}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Admin;
