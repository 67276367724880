import React from 'react'
import './AboutSection.css'
// import { faPlus } from '@fortawesome/free-solid-svg-icons'
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import Colors from '../../../utils/Colors'
export default function AboutSection() {
    // const data2 = [
    //     {
    //         imgLink: "https://src.n-ix.com/uploads/2022/07/19/ea7af8be-b9ac-431a-9910-172f40ba7f8d.png",
    //         url: "",
    //     },
    //     {
    //         imgLink: "https://src.n-ix.com/uploads/2022/12/28/a6abb1a4-94c6-4841-af41-27edc5b27858.png",
    //         url: "",
    //     },
    //     {
    //         imgLink: "https://d2m6ke2px6quvq.cloudfront.net/uploads/2020/09/14/3c75c8f6-0685-4edf-8829-b06e2286868e.png",
    //         url: "",
    //     },
    //     {
    //         imgLink: "https://d2m6ke2px6quvq.cloudfront.net/uploads/2020/07/01/f8372b4a-0f22-42fe-9bda-c259cfbc5736.png",
    //         url: "",
    //     },
    //     {
    //         imgLink: "https://src.n-ix.com/uploads/2023/04/07/6eca7104-e8ea-40ec-89ce-f851107e0791.png",
    //         url: "",
    //     },
    //     {
    //         imgLink: "https://src.n-ix.com/uploads/2022/04/11/8ed6fe92-0a9a-40fb-b920-e52dcd58d49d.png",
    //         url: "",
    //     },
    //     {
    //         imgLink: "https://d2m6ke2px6quvq.cloudfront.net/uploads/2020/12/16/7e6d8944-0090-4914-bf9c-bdb96ed419af.png",
    //         url: "",
    //     },
    //     {
    //         imgLink: "https://src.n-ix.com/uploads/2022/04/05/b645eed2-eaa3-4d1d-ab8c-e16392bb3b22.png",
    //         url: "",
    //     },
    // ]
    return (
        <div className='about-section'>
            <div className='about-section-bottom'>
                <div className='container-fluid'>
                    <div className='row'>
                        <div className='col-lg-6 col-md-12'>
                            <div className='about-sec-details'>
                                <h2>Why Choose Sparkix Tech?</h2>
                                <p>In our steadfast pursuit of seamlessly embracing the future, we pledge an unwavering commitment to transparency and a proactive approach in addressing both conventional and non-conventional IT challenges. Our resilience stands as the bedrock for achieving new heights in innovation and digital experiences. At the heart of our capabilities lies a comprehensive expertise that spans across all facets of solutions, encompassing custom product development, integrations with Generative AI, data modernization initiatives, proficient cloud operations, and strategic business consultancy. Choose us as your trusted partner, where professionalism meets unparalleled proficiency, and together, we shape the future of your digital endeavors.</p>
                                {/* <div className='d-flex flex-wrap justify-content-center justify-content-md-start text-center text-md-left'>
                                    {
                                        data2?.map((t, i) => (
                                            <a href={t.url} key={i} className="about-sec-award">
                                                <img src={t.imgLink} alt="Loading" />
                                            </a>
                                        ))
                                    }
                                </div> */}
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-lg-4 col-md-4 col-sm-12 p-0'>
                            <div className="wu-info First-box">
                                <h3>Empowered Innovation</h3>
                                <p>Empowering innovation at our core, we pioneer transformative solutions, driving progress, and shaping the future of IT excellence.</p>
                            </div>
                        </div>

                        <div className='col-lg-4 col-md-4 col-sm-12 p-0'>
                            <div className="wu-info Sec-box">
                                <h3>Guaranteed Excellence</h3>
                                <p>We think out of the box about every incoming thread and problem that customers face and provide diligent solutions.</p>
                            </div>
                        </div>

                        <div className='col-lg-4 col-md-4 col-sm-12 p-0'>
                            <div className="wu-info Third-box">
                                <h3>Impeccable Performance</h3>
                                <p>We offer software with robust speed and on time by focusing on the deliverables and tricky clients’ requirements.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
