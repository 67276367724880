import React, { useState } from "react";
import "./Card.css";
import { useNavigate } from "react-router-dom";
import { MdDelete } from "react-icons/md";
import { CiEdit } from "react-icons/ci";

import moment from "moment";
import { Button } from "react-bootstrap";
import { deleteDoc, doc } from "firebase/firestore";
import { database } from "../../firebase";
import toast from "react-hot-toast";
import Spinner from "../../pages/components/spiner/spiner";
const Card = ({
  title,
  salary,
  datePosted,
  type,
  index,
  applyDate,
  postDate,
  getAlljobs
}) => {
  const navigate = useNavigate();
  const [loading,setLoading] = useState(false);
  const handleSeeMoreClick = (index) => {
    navigate(`/dashboard/job-detail/${index}`);
  };
 
  const handleEditJob = (index) => {
    navigate(`/dashboard/edit-job/${index}`);
  };
  const specificDate = moment(postDate); 
  const currentDate = moment();
  const daysAgo = currentDate.diff(specificDate, "days");
  let postedDate = "0";
  if (daysAgo === 0) {
    postedDate = "Today";
  } else {
    postedDate = `The post is ${daysAgo} days old.`;
  }
  const deleteJob = async (jobId) => {
    try {
      setLoading(true)
      await deleteDoc(doc(database, "jobs", jobId));
      setTimeout(async () => {
        await getAlljobs();
        setLoading(false);
      }, 1000);
      toast.success("Job Delete successfully.");
    } catch (error) {
      toast.error(error.message);
      // setError(error.message);
    }
  };

if(loading){
  return(
  <div>
    <Spinner/>
  </div>
  )
}
  
  return (
    <div className="spark-job-card">
      <div className="row" >
        <div className="col-sm-12 col-md-4 m-auto">
          <div className="card-header">{title}</div>
          <div className="card-body">
            <div className="job-salary-info">{salary}</div>
            <div className="job-date-info">{postedDate}</div>
          </div>
        <div>
        <Button
       onClick={()=>handleSeeMoreClick(index)}
      size="sm"
      style={{
        padding: '0.25rem 0.5rem',
        fontSize: '0.8rem',
        borderRadius: '8px',
        fontWeight: 'bold',
        boxShadow: '0px 2px 4px rgba(49, 79, 141, 0.1)',
        background: 'linear-gradient(90deg, #284F8D 20%, #284F8D 100%)',
      }}
      >
      Job Details
    </Button>
        </div>
        </div>
        <div className="col-sm-12 col-md-4 m-auto">
          <div className="card-header-job-type">
            <div>{type ? type : ""}</div>
          </div>
        </div>
        <div className="col-sm-12 col-md-4 m-auto">
          <div className="card-body">
            <div
              style={{
                display: "flex",
                 justifyContent: "space-around",
                alignItems: "center",
                width: "100%",
                height: "100%",
              }}
            >
              <div
                style={{
                  display: "flex",
                }}
              >
                <button
                onClick={()=>handleEditJob(index)}
                 style={{
                  margin:".5rem",
                  backgroundColor: "#284F8D",
                  color: "white"
                }}
                type="button" className="btn btn-outline-primary"
                >
                  <CiEdit />
                </button>
                <button
                onClick={()=>deleteJob(index)}
                 style={{
                  margin:".5rem",
                  backgroundColor: "#284F8D",
                  color: "white"
                }}
                 type="button" className="btn btn-outline-primary "
                
                >
                  <MdDelete />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Card;
