import React, { useState, useEffect } from "react";

import "./Hero.css";
import img1 from "../../../assets/Images/hero-img5.png";
import img2 from "../../../assets/Images/hero-img2.png";
import img3 from "../../../assets/Images/hero-img3.png";

export default function Hero() {
  const [activeIndex, setActiveIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setActiveIndex((prevIndex) => (prevIndex + 1) % 3);
    }, 4000);

    return () => clearInterval(interval);
  }, []);
  return (
    <div className="hero-section">
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-6 col-md-12 p-0">
            <div className="hero-description">
              <h1 className="hero-title">
              Build AI Powered Web, Mobile, Desktop & SaaS Applications
              </h1>
              <p className="hero-text">
              At Sparkix Technologies, we specialize in transforming your ideas into reality with AI-powered solutions. Our expertise spans across designing and developing state-of-the-art web, mobile, desktop & SaaS applications, all tailored to meet your specific needs. Let us help you innovate and achieve outstanding results with cutting-edge technology that powers today's digital world.
              </p>
            </div>
          </div>

          <div className="col-lg-6 col-md-12 p-0">
            <div className="hero-slider">
              <div
                //   id="carouselExampleAutoplaying"
                className="carousel slide"
                //   data-bs-ride="carousel"
                //   data-bs-interval="4000"
              >
                <div className="carousel-inner">
                  <div
                    className={`carousel-item ${
                      activeIndex === 0 ? "active" : ""
                    }`}
                  >
                    <img src={img1} className="d-block hero-img" alt="..." />
                  </div>
                  <div
                    className={`carousel-item ${
                      activeIndex === 1 ? "active" : ""
                    }`}
                  >
                    <img src={img2} className="d-block hero-img" alt="..." />
                  </div>
                  <div
                    className={`carousel-item ${
                      activeIndex === 2 ? "active" : ""
                    }`}
                  >
                    <img src={img3} className="d-block hero-img" alt="..." />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
