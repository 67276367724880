// Dashboard.js
import React, { useEffect, useState } from "react";
import Navbar from "../../components/Navbar";
import Sidebar from "../../components/Sidebar";
import "./Dashboard.css";
import { useMediaQuery } from "react-responsive";
import { Outlet, Route, Routes } from "react-router-dom";
import Users from "../users/Users";
import AddJobs from "./AddJobs";

import { useAuth } from "../../provider/authProvider";
import JobsList from "./JobsList";
import JobDescription from "./JobDetails";
import EditJob from "./Editjob";
import Resumes from "./Resumes";

const AdminDashboard = () => {
  const { user } = useAuth();
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });
  const [isOpen, setIsOpen] = useState(isTabletOrMobile ? false : true);
  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };
  React.useEffect(() => {
    if (!isTabletOrMobile) {
      setIsOpen(true);
    } else {
      setIsOpen(false);
    }
  }, [isTabletOrMobile]);
  return (
    <div className="app">
      <Navbar toggleSidebar={toggleSidebar} />
      <Sidebar isOpen={isOpen} toggleSidebar={toggleSidebar} />

      <div
        className={`${isTabletOrMobile ? "content-mobile-screen" : "content"} ${
          isOpen ? "open" : ""
        }`}
      >
        <Routes>
          <Route  path="/" element={<Users />} />
          <Route  path="/add-jobs" element={<AddJobs uid={user?.uid} />} />
          <Route  path="/jobs-list" element={<JobsList />} />
          <Route  path="/job-detail/:slug" element={<JobDescription uid={user?.uid} />} />
          <Route  path="/edit-job/:slug" element={<EditJob uid={user?.uid} />} />
          <Route  path="/resumes" element={<Resumes/>}   />
        </Routes>
      </div>
    </div>
  );
};

export default AdminDashboard;
