import wiziLinkImg from "../assets/caseStudies/wiziLink.png";
import echoAIImg from "../assets/caseStudies/echoAI.png";
import iKonnectsImg from "../assets/caseStudies/iKonnects.png";
import AILanguageTutorImg from "../assets/caseStudies/AILanguageTutor.png";
import StudyStudentImg from "../assets/caseStudies/StudyStudent.png";
import MFImg from "../assets/caseStudies/MomentumForecasting.png";
import StoriyaImg from "../assets/caseStudies/Storiya.png";
import easyContractImg from "../assets/caseStudies/easyContract.PNG";

export const servicesArr = [
  "AI Chatbot Development & Saas Solutions",
  "Website Development",
  "Mobile App Development",
  "Machine Learning & deep learning Solutions",
  "Computer vision and NLP solutions",
  "QA Testing & Automation",
  "Logo Designing",
  "UI/UX Designing",
];

export const industriesArr = [
  "Healthcare",
  "EdTech",
  "E-Commerce",
  "Social Media",
  "Real Estate Technology",
];

export const ssChatBot = [
  "Custom Chatbot Development",
  "Custom AI solutions",
  "Natural Language Processing (NLP) Integration",
  "Conversational AI Solutions",
  "SaaS Based Chatbot Platforms",
  "Chatbot Analytics and Optimization",
];

export const ssWebApp = [
  "Custom Website Design",
  "Responsive Web Development",
  "Ecommerce Website Solutions",
  "Content Management System (CMS) Integration",
  "Website Maintenance and Support",
];

export const ssMobApp = [
  "Native Android App Development",
  "Native iOS App Development",
  "CrossPlatform Hybrid App Development",
  "Mobile App UI/UX Design",
  "App Integration and Maintenance",
];
export const ssML = [
  "Predictive Analytics",
  "Recommendation Systems",
  "Fraud Detection Algorithms",
  "Deep Neural Network Development",
  "Machine Learning Model Deployment",
];
export const ssCV = [
  "Image Recognition and Processing",
  "Video Analytics",
  "Optical Character Recognition (OCR)",
  "Sentiment Analysis",
  "Language Translation Services",
];
export const ssQA = [
  "Automated Testing Services",
  "Manual Testing and Quality Assurance",
  "Performance Testing",
  "Security Testing",
  "Continuous Integration and Continuous Testing",
];
export const ssLogo = [
  "Custom Logo Design",
  "Brand Identity Development",
  "Logo Redesign and Brand Refresh",
  "Vector Logo Creation",
  "Logo Style Guide Creation",
];

export const ssUIUX = [
  "User Interface (UI) Design",
  "User Experience (UX) Design",
  "Wireframing and Prototyping",
  "Usability Testing",
  "Design System Implementation",
];

export const caseStudies = [
  {
    title: "WiziLink - Smart Team Answers",
    description:
      "WiziLink is the ultimate platform for teams seeking quick and accurate answers. With advanced AI and document data training, it transforms the way your team communicates, making information readily available. Elevate your team's knowledge sharing with WiziLink – where collaboration meets intelligence.",
    imgLink: wiziLinkImg,
    bgColor: "#EAEAEA",
    textColor:"black",
  },
  {
    title: "ECHO AI - Precision Dosage Advisor",
    description:
      "ECHO AI, your intelligent dosage advisor, utilizes advanced AI for swift and precise dosage recommendations, enhancing doctors' decision-making. Elevate patient care with personalized treatment plans, combining cutting-edge technology and medical expertise for optimal outcomes.",
    imgLink: echoAIImg,
    bgColor: "#EBEEF6",
    textColor:"black",
  },
  {
    title: "iKonnects - Service-Driven Social Hub",
    description:
      "iKonnects, your service-driven social hub, redefines connection by seamlessly linking people for diverse services. Experience a unique social media platform that goes beyond the ordinary, fostering a community where individuals connect to access a myriad of services. Join iKonnects and explore a world where connections empower services and services strengthen connections.",
    imgLink: iKonnectsImg,
    bgColor: "#FEFEFE",
    textColor:"black",
  },
  {
    title: "AI Language Tutor - Learn Anywhere, Anytime",
    description:
      "AI Language Tutor transforms language learning into a dynamic experience. Similar to WhatsApp, this innovative platform allows users to effortlessly send and receive voice/text messages for interactive language lessons. Break language barriers with AI Language Tutor, making learning engaging, personalized, and as easy as having a conversation.",
    imgLink: AILanguageTutorImg,
    bgColor: "#FFFFFF",
    textColor:"black",
  },
  {
    title: "Study Student - Your Instant Academic Companion",
    description:
      "Study Student is your go-to platform for instant academic answers. Students can ask questions in any format—text or image, and receive quick and accurate responses. Revolutionize your learning experience with Study Student, where answers are just a question away.",
    imgLink: StudyStudentImg,
    bgColor: "#FFFFFF",
    textColor:"black",
  },
  {
    title: "Momentum - AI-Powered Market Navigation",
    description:
    "Momentum Forecasting empowers you to navigate financial markets with precision using advanced AI. Stay ahead in the world of finance by leveraging the predictive capabilities of MomentumForecasting for informed and strategic decision-making. Master the art of market dynamics with the next level of forecasting.",
    imgLink: MFImg,
    bgColor: "#283A56",
    textColor:"white",
  },
  {
    title: "Storiya - AI-Powered Kid's Storyteller",
    description:
    "Storiya is an enchanting story generation app for kids, brought to life by the magic of AI. Spark creativity and imagination as Storiya weaves captivating tales for young minds, making storytelling an unforgettable adventure. Unleash the power of imagination with Storiya, where every tale is a journey into a world of wonder.",
    imgLink: StoriyaImg,
    bgColor: "#FFFFFF",
    textColor:"black",
  },
  {
    title: "Easy Contract Pro - Streamlining Deals, Crafting Contracts",
    description:
      "Easy Contract Pro simplifies the contract-writing process for dealers, facilitating smooth negotiations between parties. Empower your business transactions with precision and ease by creating professional contracts effortlessly. Elevate your deal-making game with Easy Contract Pro – where agreements are made simple.",
    imgLink: easyContractImg,
    bgColor: "#0D1019",
    textColor:"white",
  },
];
export const Profession = [
  { value: '', label: 'All jobs' },
  { value: 'software_engineer', label: 'Software Engineer' },
  { value: 'web_developer', label: 'Web Developer' },
  { value: 'data_scientist', label: 'Data Scientist' },
  { value: 'network_engineer', label: 'Network Engineer' },
  { value: 'cyber_security_specialist', label: 'Cyber Security Specialist' },
  { value: 'cloud_architect', label: 'Cloud Architect' },
  { value: 'UI_UX_designer', label: 'UI/UX Designer' },
  { value: 'AI_machine_learning_engineer', label: 'AI/Machine Learning Engineer' }
];


export const getProfessionValue = (value) => {
  const profession = Profession.find((prof) => prof.value === value);
  return profession ? profession.label : null;
};



export const Location = [
  { value: '', label: 'All Locations' },
  { value: 'remote', label: 'Remote' },
  { value: 'onsite', label: 'Onsite' },
  { value: 'hybrid', label: 'Hybrid' }
];


export const getLocationValue = (value) => {
  const location = Location.find((prof) => prof.value === value);
  return location ? location.label : null;
};



export const Experience = [
  { value: '1', label: '1 Year' },
  { value: '2', label: '2 Years' },
  { value: '3', label: '3 Years' },
  { value: '4', label: '4 Years' },
  { value: '5', label: '5 Years' },
  { value: '6', label: '6 Years' },
  { value: '7', label: '7 Years' },
  { value: '8', label: '8 Years' },
  { value: '9', label: '9 Years' },
  { value: '10', label: '10 Years' },
  { value: '11', label: '11 Years' },
  { value: '12', label: '12 Years' },
  { value: '13', label: '13 Years' },
  { value: '14', label: '14 Years' },
  { value: '15', label: '15 Years' },
  { value: '16', label: '16 Years' },
  { value: '17', label: '17 Years' },
  { value: '18', label: '18 Years' },
  { value: '19', label: '19 Years' },
  { value: '20', label: '20 Years' }
];

export const CareerLevel = [
  { value: 'beginner', label: 'Beginner' },
  { value: 'intermediate', label: 'Intermediate' },
  { value: 'experienced', label: 'Experienced' }
];

export const JobType = [
  { value: 'part_time', label: 'Part Time' },
  { value: 'full_time', label: 'Full Time' },
  { value: 'contract', label: 'Contract' },
  { value: 'freelance', label: 'Freelance' }
];

export const getJobTypeValue = (value) => {
  const jobType = JobType.find((prof) => prof.value === value);
  return jobType ? jobType.label : null;
};



export const TotalPositions = Array.from({ length: 20 }, (_, index) => ({
  value: (index + 1).toString(),
  label: (index + 1).toString()
}));
