import React, { useState } from "react";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import Navbar from "../../components/Navbar/Navbar";
import careerImg from "../../../assets/Images/career.png";
import emailjs from "@emailjs/browser";
import "./admin.css";

import { useLocation } from "react-router-dom";
import toast from "react-hot-toast";
import { database, Storage } from "../../../firebase";
import { addDoc, collection, doc, updateDoc } from "firebase/firestore";
const ApplyJob = () => {
  const location = useLocation();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [resume, setResume] = useState(null);
  const [loading,setLoading] = useState(false);
  const professType = location?.state?.job?.profession;  
  const serviceID = "service_hm5pt49";
  const PublicKey = "Y7eK-EAuAjWSGLC82";
  const PrivateKey = "kjN8LZjsIIfv0zys1Aq1g";
  const Template_id = "template_iu7e1kp";

  const fileChangeHandler = async (e) => {
    try {
      const file = e.target.files[0];
      if (file) {
        console.log(file);
        setResume(file);
      }
    } catch (e) {
      toast.error(e.message);
      console.log(e);
    }
  };

  const handleSubmit = async (e) => {
    try {
      if(!professType){
        toast.error("Please select the job again from the previous page.");
        return;
      }
      if (!resume || !name || !email ) return toast.error("Please fill in all required fields.");
      setLoading(true);
      const fileName = `${Date.now()}-${resume.name}`;
      const storageRef = ref(Storage, `resumes/${fileName}`);
      await uploadBytes(storageRef, resume);
      const url = await getDownloadURL(storageRef);
      const currentDate = new Date();
      const docRef = await addDoc(collection(database, "resumes"), {
        name,
        email,
        fileName,
        resume: url,
        date:currentDate,
        professType
      });
      const jobId = docRef.id;
      await updateDoc(doc(database, "resumes", jobId), { id: jobId });
      toast.success("Resume send successfully.");
      setLoading(false);
    } catch (err) {
      setLoading(false);
      toast.error(err.message);
      console.log(err);
    }
  };

  return (
    <div>
      <Navbar />
      <div  
      style={{
        marginTop:"5rem"
      }}
      className="job-admin-container">
        <div className="flex flex-col items-center justify-center">
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img src={careerImg} width="100" />
          </div>
          <h1 className="text-heading mb-2 text-4xl font-bold text-center">
            Apply for a Job
            <span style={{ color: "rgb(27, 79, 146)" }}>
              {" "}
              Sparkix Technologies{" "}
            </span>
          </h1>
        </div>
        <div className="container mt-5">
          <div className="">
            <div className="col-md-12">
              <div className="card">
                <div className="card-header"> Upload your details.</div>
                <div className="card-body">
                  <div className="row pt-1">
                    <div className="col-sm-12">
                      <div className="form-group">
                        <div className="select-heading">
                          <label>Name</label>
                        </div>
                        <input
                          type="text"
                          className="form-control"
                          value={name}
                          onChange={(e) => setName(e.target.value)}
                          name="name"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row pt-1">
                    <div className="col-sm-12">
                      <div className="form-group">
                        <div className="select-heading">
                          <label>Email</label>
                        </div>
                        <input
                          name="email"
                          type="text"
                          className="form-control"
                          onChange={(e) => setEmail(e.target.value)}
                          value={email}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row pt-1">
                    <div className="form-group">
                      <label>Resume (PDF)</label>
                      <input
                        type="file"
                        name="resume"
                        className="form-control"
                        accept=".pdf"
                        onChange={(e) => fileChangeHandler(e)}
                        required
                      />
                    </div>
                  </div>
                  <div className="job-submit-container pt-4">
                    <button
                    disabled={loading}
                      onClick={handleSubmit}
                      type="button"
                      className="apply-job-submit-button btn btn-primary"
                    >
                      {loading?"loading..":"Apply"}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ApplyJob;
