import React from "react";
import Select from "react-select";
import "./Select.css";
import chroma from "chroma-js";
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';

const CustomSelect = ({ name, options,onChange,value }) => {
  const colourStyles = {
    control: (styles) => ({ ...styles, backgroundColor: "white" }),
    option: (styles, { isDisabled, isFocused, isSelected }) => {
      return {
        color: isFocused ? "white" : "#284F8D",
        cursor: isDisabled ? "not-allowed" : "default",
        backgroundColor: isFocused ? "#284F8D" : "inherit",
        padding: '4px 12px',
      };
    },
  };

  return (
    <div className="job-select">
      <div className="select-heading">{name}</div>
      <Form.Control
        onChange={onChange}
        className="basic-single"
        classNamePrefix="select"
        placeholder={`Search ${name}`}
        // defaultValue={options[0]}
        value={value}
        // options={options}
        name="color"
        styles={colourStyles}
      />
    </div>
  );
};

export default CustomSelect;
