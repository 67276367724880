import React, { useState } from "react";
import useJobs from "../../hooks/useJobs";
import '../Frontend/Career/Career.css'
import Spinner from "../../components/spiner/Spiner";
import Card from "../../components/card/Card.jsx";
import LoadMoreButton from "../../components/LoadMore.jsx";

const JobsList = () => {
  const { jobs, loading, fetchMoreJobs ,totalJobs,getAlljobs} = useJobs();

  return (
    <div>

      <div className="admin-sparKix-career-page">
        <div className="sparKix-career-page-container">
          <div>
            <div className="search-container">
              <div>
              <div
              style={{
                fontSize: "1.4rem",
                fontWeight: "bold",
                color: "#284F8D",
                borderRadius: "10px",
                fontFamily: "Poppins",
              }}
            >
              jobs List
            </div>
                <div className=" pb-3"></div>

                {loading ? (
                  <div>
                    <Spinner />
                  </div>
                ) : jobs?.length > 0 ? (
                  jobs.map((job, index) => (
                    <Card
                      key={index}
                      title={job.profession}
                      salary={job.salaryExpected}
                      datePosted={job.jobList}
                      type={job?.location}
                      applyDate={job.applyDate}
                      postDate={job.postDate}
                      index={job.id}
                      getAlljobs={getAlljobs}
                    />
                  ))
                ) : (
                  <div style={{ textAlign: "center", fontWeight: "bold" }}>
                    No List job
                  </div>
                )}
              </div>

              <div
                style={{
                  float: "right",
                  marginBottom: "2rem",
                }}
              >
                {jobs?.length > 0 &&jobs.length < totalJobs&& <LoadMoreButton onClick={fetchMoreJobs} />}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default JobsList;
