import React, { useEffect, useState } from "react";
import "./Events.css";
import { faAngleUp, faCircleXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ReactCurvedText from "react-curved-text";
import { useStateContext } from "../../../context/AppContext";

export default function Events() {
  const { dark } = useStateContext();
  const [activeEvent, setactiveEvent] = useState("");
  const [smaller, setsmaller] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      const smallScreen = window.innerWidth < 1070 ? true : false;
      setsmaller(smallScreen);
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);


  console.log(activeEvent,"activeEvent")

  return (
    <div className="events-section">
      <div className="events-section-wrap">
        <h1>Our work flow</h1>
        <div className="events-cricle" style={{ borderColor: dark }}>
          <FontAwesomeIcon icon={faAngleUp} className="ec-icon1" />
          <FontAwesomeIcon icon={faAngleUp} className="ec-icon2" />
          <FontAwesomeIcon icon={faAngleUp} className="ec-icon3" />
          <FontAwesomeIcon icon={faAngleUp} className="ec-icon4" />

          <button
            onMouseEnter={() => setactiveEvent("First")}
            className={`ec-btn ec-btn1  
            ${activeEvent === "First" && "active"}`}
            onClick={() => setactiveEvent("First")}
            style={{ backgroundColor: dark }}
          >
            <ReactCurvedText
              width={"100%"}
              height={"100%"}
              cx={smaller ? 135 : 150}
              cy={smaller ? 130 : 150}
              rx={100}
              ry={100}
              startOffset={smaller ? 35 : 30}
              reversed={true}
              text="Design"
              textProps={{
                style: { fontSize: smaller ? 20 : 24, fill: "white" },
              }}
              textPathProps={null}
              tspanProps={null}
              ellipseProps={null}
              svgProps={null}
            />
          </button>

          <button
            onMouseEnter={() => setactiveEvent("Second")}
            className={`ec-btn ec-btn2 ${activeEvent === "Second" && "active"}`}
            onClick={() => setactiveEvent("Second")}
            style={{ backgroundColor: dark }}
          >
            <ReactCurvedText
              width={"100%"}
              height={"100%"}
              cx={smaller ? 5 : 28}
              cy={smaller ? 132 : 150}
              rx={smaller ? 95 : 90}
              ry={100}
              startOffset={smaller ? 160 : 140}
              reversed={true}
              text="Development"
              textProps={{
                style: { fontSize: smaller ? 20 : 24, fill: "white" },
              }}
              textPathProps={null}
              tspanProps={null}
              ellipseProps={null}
              svgProps={null}
            />
          </button>

          <button
            onMouseEnter={() => setactiveEvent("Third")}
            className={`ec-btn ec-btn3 ${activeEvent === "Third" && "active"}`}
            onClick={() => setactiveEvent("Third")}
            style={{ backgroundColor: dark }}
          >
            <ReactCurvedText
              width={"100%"}
              height={"100%"}
              cx={smaller ? 138 : 145}
              cy={smaller ? 10 : 15}
              rx={smaller ? 118 : 115}
              ry={smaller ? 100 : 120}
              startOffset={0}
              reversed={false}
              text={`Release & main${smaller ? "" : "t"}.`}
              textProps={{
                style: { fontSize: smaller ? 20 : 24, fill: "white" },
              }}
              textPathProps={null}
              tspanProps={null}
              ellipseProps={null}
              svgProps={null}
            />
          </button>

          <button
            onMouseEnter={() => setactiveEvent("Fourth")}
            className={`ec-btn ec-btn4 ${activeEvent === "Fourth" && "active"}`}
            onClick={() => setactiveEvent("Fourth")}
            style={{ backgroundColor: dark }}
          >
            <ReactCurvedText
              width={"100%"}
              height={"100%"}
              cx={smaller ? 5 : 28}
              cy={32}
              rx={95}
              ry={smaller ? 75 : 100}
              startOffset={150}
              reversed={false}
              text="Testing & QA"
              textProps={{
                style: { fontSize: smaller ? 20 : 24, fill: "white" },
              }}
              textPathProps={null}
              tspanProps={null}
              ellipseProps={null}
              svgProps={null}
            />
          </button>

          <div className="ec-center-content">
            <div className="ec-logo-content">
              {/* <span className="ec-logo">Satefy Tech</span> */}
              <h3>Sparkix Technologies</h3>
              {/* <span
                className="wheel-product-desc"
                data-quadranttitle="Event lifecycle"
                data-alttext="Seamless event marketing and management, real results"
              >
                Seamless event marketing and management, real results
              </span> */}
            </div>
          </div>

          <div>
            <div
              className={`ec-popup ec-popup1 ${
                activeEvent === "First" && "active"
              }`}
              style={{ border: `1px solid ${dark}` }}
            >
              <div className="triangle-svg"></div>
              <div
                className="ec-popup-close"
                onClick={() => setactiveEvent("")}
              >
                <FontAwesomeIcon icon={faCircleXmark} className="x-mark" />
              </div>
              <div className="ac-content2">
                <div>
                  <label>System Architecture</label>
                </div>
                <div>
                  <label>Data Design</label>
                </div>
                <div>
                  <label>User Interface (UI) Design</label>
                </div>
                <div>
                  <label>Detailed Module Design</label>
                </div>
                <div>
                  <label>Design Validation</label>
                </div>
              </div>
            </div>

            <div
              className={`ec-popup ec-popup2 ${
                activeEvent === "Second" && "active"
              }`}
              style={{ border: `1px solid ${dark}` }}
            >
              <div className="triangle-svg"></div>
              <div
                className="ec-popup-close"
                onClick={() => setactiveEvent("")}
              >
                <FontAwesomeIcon icon={faCircleXmark} className="x-mark" />
              </div>
              <div className="ac-content2">
                <div>
                  <label>Coding</label>
                </div>
                <div>
                  <label>Code Reviews</label>
                </div>
                <div>
                  <label>Unit Testing</label>
                </div>
                <div>
                  <label>Integration Testing</label>
                </div>
                <div>
                  <label>Version Control</label>
                </div>
              </div>
            </div>

            <div
              className={`ec-popup ec-popup3 ${
                activeEvent === "Third" && "active"
              }`}
              style={{ border: `1px solid ${dark}` }}
            >
              <div className="triangle-svg"></div>
              <div
                className="ec-popup-close"
                onClick={() => setactiveEvent("")}
              >
                <FontAwesomeIcon icon={faCircleXmark} className="x-mark" />
              </div>
              <div className="ac-content2">
                <div>
                  <label>Martech integrations</label>
                </div>
                <div>
                  <label>Event &amp; attendee insights</label>
                </div>
                <div>
                  <label>Surveys</label>
                </div>
                <div>
                  <label>Lead management</label>
                </div>
                <div>
                  <label>Engagement scoring</label>
                </div>
              </div>
            </div>

            <div
              className={`ec-popup ec-popup4 ${
                activeEvent === "Fourth" && "active"
              }`}
              style={{ border: `1px solid ${dark}` }}
            >
              <div className="triangle-svg"></div>
              <div
                className="ec-popup-close"
                onClick={() => setactiveEvent("")}
              >
                <FontAwesomeIcon icon={faCircleXmark} className="x-mark" />
              </div>
              <div className="ac-content2">
                <div>
                  <label>Test Planning</label>
                </div>
                <div>
                  <label>Test Case Design</label>
                </div>
                <div>
                  <label>Execution of Test Cases</label>
                </div>
                <div>
                  <label>Defect Tracking and Management</label>
                </div>
                <div>
                  <label>Regression Testing</label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
