import React, { createContext, useContext, useMemo, useState } from "react";
import { signOut } from "firebase/auth";
import { auth } from "../firebase";
import toast from "react-hot-toast";
import { Navigate } from "react-router-dom";

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const [token, setToken] = useState(localStorage.getItem("token"));
  const [user, setUser] = useState(JSON.parse(localStorage.getItem("user")));
  React.useEffect(()=>{
    if(localStorage.getItem("token")){
      // eslint-disable-next-line no-unused-expressions
      setToken(localStorage.getItem("token")),
      (setUser(JSON.parse(localStorage.getItem("user"))));
    }
  },[setToken, setUser, token])
  

  const login = (accessToken,userDoc) => {
    try {
      setToken(accessToken);
      
      localStorage.setItem("token", accessToken);
      localStorage.setItem("user", JSON.stringify(userDoc));

    } catch (e) {
      toast.error(e.message);
      return e;
    }
  };

  const logout = async () => {
    try {
      setToken(null);
      setUser(null);
      const out = await signOut(auth);
      toast.success("You have been logged out");
      localStorage.removeItem("token");
      localStorage.removeItem("user");
      return out;
    } catch (e) {
      toast.error(e.message);
      return e;
    }
  };

  const contextValue = useMemo(
    () => ({
      token,
      setUser,
      setToken,
      login,
      logout,
      user
    }),
    [token,user]
  );

  return (
    <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};

export default AuthProvider;
