import React, { useState } from "react";
import "./chatboot.css";
import chatboot from "../../../assets/Images/chatboticon.png";
import { NluxChatbot } from "./Nluxchatbot";

const ChatBot = ({ setIsOpen, isOpen }) => {
  
  const toggleChat = () => {
    setIsOpen(!isOpen); 
  };



  return (
    <div className={`spark-chatbot-container ${isOpen ? "open" : ""}`}>
      <div className="chatbot-header" onClick={toggleChat}>
      Sparkix AI Assistant
      </div>

      <div className="">
        <div className="chatbot-body">
            <NluxChatbot/>
         
        </div>
      </div>
    </div>
  );
};

export default ChatBot;
