import React from "react";
import "./Card.css";
import { useNavigate } from "react-router-dom";
import { getJobTypeValue, getLocationValue, getProfessionValue } from "../../../utils/Data";
import moment from "moment";
const Card = ({
  title,
  salary,
  datePosted,
  type,
  index,
  applyDate,
  postDate,
}) => {
 
  const navigate = useNavigate();
  const handleButtonClick = (index) => {
    navigate(`/job-insights/${index}`);
  };
  const specificDate = moment(postDate); // specific date
  const currentDate = moment();
  const daysAgo = currentDate.diff(specificDate, "days");

  let postedDate = "0";
  if (daysAgo === 0) {
    postedDate = "Today";
    
  } else {
    postedDate = `The post is ${daysAgo} days old.`;
  }
  return (
    <div className="spark-job-card">
      <div className="row">
        <div className="col-sm-12 col-md-4 m-auto">
          <div className="card-header">{title}</div>
          <div className="card-body">
            <div className="job-salary-info">{salary}</div>
            <div className="job-date-info">{postedDate}</div>
          </div>
        </div>
        <div className="col-sm-12 col-md-4 m-auto">
          <div className="card-header-job-type">
            <div>{type ? type : ""}</div>
          </div>
        </div>
        <div className="col-sm-12 col-md-4 m-auto">
          <div className="card-body">
            <button
              className="btn btn-primary "
              style={{
                backgroundColor: "#284F8D",
              }}
              onClick={() => handleButtonClick(index)}
            >
              Get Job insights
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Card;
