import React from 'react'
import { Button } from 'react-bootstrap'
import './Button.css'
const CustomButton = (props) => {
    // eslint-disable-next-line react/prop-types
    const {onClick, children,className,style}= props
  return (
    <Button style={style} className={`${className} common-button`} onClick={()=>onClick()}>{children}</Button>
  )
}

export default CustomButton