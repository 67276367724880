// ResponsiveTable.jsx
import React from "react";
import { Table } from "react-bootstrap";
import TableHeader from "./Header";
import TableRow from "./Row";

const ResponsiveTable = ({ headers, data }) => {
  return (
    <div className="table-responsive" style={{
        // width: "100%/",
        // height: "100%",
    
    }}>
      <Table striped bordered hover>
        <TableHeader headers={headers} />
        <TableRow   headers={headers} data={data} />
      </Table>
    </div>
  );
};

export default ResponsiveTable;
