import React from 'react'
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom'

import Home from './Frontend/Home/Home'
import ScheduleCall from './Frontend/ScheduleCall/ScheduleCall'
import AboutUs from './Frontend/AboutUs/AboutUs'
import Career from './Frontend/Career/Career'
import CareerDescription from './Frontend/Career/CareerDescription'
import ApplyJob from './Frontend/admin/ApplyJob'
import Login from './login/Login'
import { useAuth } from '../provider/authProvider'
import AdminDashboard from './dashboard/Dashboard'

import AiChatbot from './services/Aichatbot'

export default function Index() {
    const { token ,setToken,setUser} = useAuth();
    return (
        <BrowserRouter>
            <Routes>
                <Route path='/admin-login' element={<Login/>}/>
                <Route   path="/dashboard/*" element={token?<AdminDashboard />:<Navigate to="/admin-login"/> }/>  
                <Route path='/' element={<Home />} />
                <Route path='/about-us' element={<AboutUs />} />
                <Route path='/schedule-call' element={<ScheduleCall />} />
                <Route path='/careers' element={<Career />} />
                <Route path='/apply-job' element={<ApplyJob />} />
                <Route path='/ai-chatbot-and-saas-solutions' element={<AiChatbot/>} />
                <Route path='/job-insights/:slug' element={<CareerDescription/>} />
            </Routes>
        </BrowserRouter>
    )
}
