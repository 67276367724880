export const Profession = [
  {
    value: "software_engineer",
    label: "Software Engineer",
    name: "profession",
  },
  { value: "web_developer", label: "Web Developer", name: "profession" },
  { value: "data_scientist", label: "Data Scientist", name: "profession" },
  { value: "network_engineer", label: "Network Engineer", name: "profession" },
  {
    value: "cyber_security_specialist",
    label: "Cyber Security Specialist",
    name: "profession",
  },
  { value: "cloud_architect", label: "Cloud Architect", name: "profession" },
  { value: "UI_UX_designer", label: "UI/UX Designer", name: "profession" },
  {
    value: "AI_machine_learning_engineer",
    label: "AI/Machine Learning Engineer",
    name: "profession",
  },
];

export const Location = [
  { value: "remote", label: "Remote", name: "location" },
  { value: "onsite", label: "Onsite", name: "location" },
  { value: "hybrid", label: "Hybrid", name: "location" },
];
export const Experience = [
  { value: "1", label: "1 Year", name: "yearsOfExperience" },
  { value: "2", label: "2 Years", name: "yearsOfExperience" },
  { value: "3", label: "3 Years", name: "yearsOfExperience" },
  { value: "4", label: "4 Years", name: "yearsOfExperience" },
  { value: "5", label: "5 Years", name: "yearsOfExperience" },
  { value: "6", label: "6 Years", name: "yearsOfExperience" },
  { value: "7", label: "7 Years", name: "yearsOfExperience" },
  { value: "8", label: "8 Years", name: "yearsOfExperience" },
  { value: "9", label: "9 Years", name: "yearsOfExperience" },
  { value: "10", label: "10 Years", name: "yearsOfExperience" },
  { value: "11", label: "11 Years", name: "yearsOfExperience" },
  { value: "12", label: "12 Years", name: "yearsOfExperience" },
  { value: "13", label: "13 Years", name: "yearsOfExperience" },
  { value: "14", label: "14 Years", name: "yearsOfExperience" },
  { value: "15", label: "15 Years", name: "yearsOfExperience" },
  { value: "16", label: "16 Years", name: "yearsOfExperience" },
  { value: "17", label: "17 Years", name: "yearsOfExperience" },
  { value: "18", label: "18 Years", name: "yearsOfExperience" },
  { value: "19", label: "19 Years", name: "yearsOfExperience" },
  { value: "20", label: "20 Years", name: "yearsOfExperience" },
];

export const SalaryExpected = [
  {
    value: "20k-30k",
    label: "20k-30k",
    name: "salaryExpected",
  },
  {
    value: "30k-40k",
    label: "30k-40k",
    name: "salaryExpected",
  },
  {
    value: "40k-50k",
    label: "40k-50k",
    name: "salaryExpected",
  },
  {
    value: "50k-60k",
    label: "50k-60k",
    name: "salaryExpected",
  },
  {
    value: "60k-70k",
    label: "60k-70k",
    name: "salaryExpected",
  },
  {
    value: "70k-80k",
    label: "70k-80k",
    name: "salaryExpected",
  },
  {
    value: "80k-90k",
    label: "80k-90k",
    name: "salaryExpected",
  },
  {
    value: "90k-100k",
    label: "90k-100k",
    name: "salaryExpected",
  },
  {
    value: "100k-110k",
    label: "100k-110k",
    name: "salaryExpected",
  },
  {
    value: "110k-120k",
    label: "110k-120k",
    name: "salaryExpected",
  },
  {
    value: "120k-130k",
    label: "120k-130k",
    name: "salaryExpected",
  },
  {
    value: "130k-140k",
    label: "130k-140k",
    name: "salaryExpected",
  },
  {
    value: "140k-150k",
    label: "140k-150k",
    name: "salaryExpected",
  },
  {
    value: "150k-160k",
    label: "150k-160k",
    name: "salaryExpected",
  },
  {
    value: "160k-170k",
    label: "160k-170k",
    name: "salaryExpected",
  },
  {
    value: "170k-180k",
    label: "170k-180k",
    name: "salaryExpected",
  },
  {
    value: "180k-190k",
    label: "180k-190k",
    name: "salaryExpected",
  },
  {
    value: "190k-200k",
    label: "190k-200k",
    name: "salaryExpected",
  },
  {
    value: "200k-210k",
    label: "200k-210k",
    name: "salaryExpected",
  },
  {
    value: "210k-220k",
    label: "210k-220k",
    name: "salaryExpected",
  },
  {
    value: "220k-230k",
    label: "220k-230k",
    name: "salaryExpected",
  },
  {
    value: "230k-240k",
    label: "230k-240k",
    name: "salaryExpected",
  },
  {
    value: "240k-250k",
    label: "240k-250k",
    name: "salaryExpected",
  },
  {
    value: "250k-260k",
    label: "250k-260k",
    name: "salaryExpected",
  },
  {
    value: "260k-270k",
    label: "260k-270k",
    name: "salaryExpected",
  },
  {
    value: "270k-280k",
    label: "270k-280k",
    name: "salaryExpected",
  },
  {
    value: "280k-290k",
    label: "280k-290k",
    name: "salaryExpected",
  },
  {
    value: "290k-300k",
    label: "290k-300k",
    name: "salaryExpected",
  },
  {
    value: "300k-400k",
    label: "300k-400k",
    name: "salaryExpected",
  }
];





export const EducationLevels = [
  {
    value: "Bachelor's in BSCS/BSIT/BSES",
    label: "Bachelor's in BSCS/BSIT/BSES",
    name: "education",
  },
  {
    value: "Master's in MSCS/MSIT/MSES",
    label: "Master's in MSCS/MSIT/MSES",
    name: "education",
  },

  {
    value: "Bachelor of Computer Science",
    label: "Bachelor of Computer Science",
    name: "education",
  },
  {
    value: "Diploma in Computer Science",
    label: "Diploma in Computer Science",
    name: "education",
  },
];







export const CareerLevel = [
  { value: "beginner", label: "Beginner", name: "careerLevel" },
  { value: "intermediate", label: "Intermediate", name: "careerLevel" },
  { value: "experienced", label: "Experienced", name: "careerLevel" },
];

export const JobType = [
  { value: "part_time", label: "Part Time", name: "jobType" },
  { value: "full_time", label: "Full Time", name: "jobType" },
  { value: "contract", label: "Contract", name: "jobType" },
  { value: "freelance", label: "Freelance", name: "jobType" },
];

export const TotalPositions = Array.from({ length: 20 }, (_, index) => ({
  value: (index + 1).toString(),
  label: (index + 1).toString(),
  name: "totalPositions",
}));
