import { useState, useEffect, useCallback } from "react";
import {
  collection,
  query,
  onSnapshot,
  limit,
  startAfter,
  getDocs,
  orderBy,
} from "firebase/firestore";
import { database } from "../firebase";

const useResumes = () => {
  const [resumes, setResumes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [lastDocument, setLastDocument] = useState(null);
  const [totalResumes, setTotalResumes] = useState(0);

  const getAllResumes= useCallback(async() => {
    try {
      const q = query(
        collection(database, "resumes"),
        orderBy("date", "desc"),
        limit(3)
      );
      const unsubscribe = onSnapshot(
        q,
        async (querySnapshot) => {
          const totalQuery = query(collection(database, "resumes"));
          const totalSnapshot = await getDocs(totalQuery);
          setTotalResumes(totalSnapshot.size);
          const fetchedJobs = [];
          querySnapshot.forEach((doc) => {
            fetchedJobs.push(doc.data());
          });
          setResumes(fetchedJobs);
          setLoading(false);
          setLastDocument(querySnapshot.docs[querySnapshot.docs.length - 1]);
        },
        (error) => {
          setError(error);
          setLoading(false);
        }
      );
      return () => unsubscribe();
    } catch (error) {
      console.log(error);
    }
  },[])
  useEffect(() => {
    setResumes([])
    getAllResumes();
  }, [getAllResumes]);

  const fetchMoreJobs = async () => {
    if (lastDocument) {
      const q = query(
        collection(database, "resumes"),
        orderBy("date", "desc"),
        startAfter(lastDocument),
        limit(3)
      );

      const querySnapshot = await getDocs(q);
      const fetchedJobs = [];
      querySnapshot.forEach((doc) => {
        fetchedJobs.push(doc.data());
      });
      setResumes((prevJobs) => [...prevJobs, ...fetchedJobs]);
      setLastDocument(querySnapshot.docs[querySnapshot.docs.length - 1]);
    }
  };

  return { resumes, loading, error, fetchMoreJobs,getAllResumes, totalResumes };
};

export default useResumes;
