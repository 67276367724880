import {AiChat} from '@nlux/react';
import {useChatAdapter, ChatAdapterOptions} from '@nlux/nlbridge-react';
import '@nlux/themes/nova.css';
import chatboticon from "../../../assets/Images/chatboticon.png";
import { createStreamAdapter, streamAdapter } from './adapter';
import { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
// const adapterOptions = {
//     url: 'https://sparkixtech.pythonanywhere.com/chat',
// };

export const NluxChatbot = () => {
  const [sessionId, setSessionId] = useState('');
  useEffect(() => {
    const generateRandomId = () => uuidv4();
    setSessionId(generateRandomId());
  }, []);

  return (
    <AiChat
    adapter={createStreamAdapter(sessionId)}
      personaOptions={{
        bot: {
          name: 'Sparkix AI Assistant',
          tagline: 'Your Sparkix Technologies AI Assistant',
          picture: chatboticon,
          pictureClass: 'bot-picture'
        },

      }}
      promptBoxOptions={{
        placeholder: 'How can I help you?',
      }}
      layoutOptions={{
        height: 420,
        maxWidth: '100%',
      }}
    />
  );
};