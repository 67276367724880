// useUsers.js
import { useState, useEffect } from "react";
import { database } from "../firebase";
import { collection, getDocs, onSnapshot } from "firebase/firestore";

const useUsers = () => {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    try {
      setLoading(true)
      const unsubscribe = onSnapshot(
        collection(database, "users"),
        (querySnapshot) => {
          const fetchedUsers = [];
          querySnapshot.forEach((doc) => {
            fetchedUsers.push(doc.data());
          });
          setUsers(fetchedUsers);
          setLoading(false);
        },
        (error) => {
          setError(error);
          setLoading(false);
        }
      );
      return () => unsubscribe();
    } catch (error) {
      console.log(error);
    }
  }, []);

  return { users, loading, error };
};

export default useUsers;
