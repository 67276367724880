import chatboot from "../../../assets/Images/chatboot.png";
import React from "react";
import "./HeroSection.css"; // Import your CSS file
import { Container, Row, Col } from "react-bootstrap";

const HeroSection = () => {
  return (
    <div className="spark_ai_services_hero-section ">
      <Container>
        <Row className="align-items-center">
          <Col md={6} className="order-md-1 order-2">
            <div className=" text-center text-md-left">
              <h1 className="welcome-text">
              Transform your business with AI-powered chatbots and AI SaaS solutions, with us
              </h1>
              <p className="welcome-paragraph">
                Leverage Cutting-Edge AI Technology to Transform Your Business.
              </p>
            </div>
          </Col>
          <Col md={6} className="order-md-2 order-1">
            <div className="chatbot">
              <img
                src={chatboot}
                className="spark_ai_services-img-fluid"
                alt="Chat Bot"
              />
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default HeroSection;
