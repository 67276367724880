import React, { useEffect } from "react";
import { Element } from "react-scroll";
import { useNavigate, useLocation } from "react-router-dom";
import { scroller } from "react-scroll";

import "./Home.css";
import Navbar from "../../components/Navbar/Navbar";
import Hero from "../../components/Hero/Hero";
import Slider from "../../components/Slider/Slider";
import AboutSection from "../../components/AboutSection/AboutSection";
import Services from "../../components/Services/Services";
import Events from "../../components/Events/Events";
import OurProcess from "../../components/OurProcess/OurProcess";
// import Expertise from '../../components/Expertise/Expertise'
// import Portfolio from '../../components/Portfolio/Portfolio'
import CaseStudy from "../../components/CaseStudy/CaseStudy";
import Footer from "../../components/Footer/Footer";
import OurClients from "../Ourclients/Clients";
import SparkAccordian from "../../services/FAQs/Faq";
import AdvanceFeatures from "../../../pages/Frontend/Ourclients/AdvanceFeatures.jsx";



export default function Home() {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    navigate("/", { state: { dataObj: null } });
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    setTimeout(() => {
      scroller.scrollTo(`/${location?.state?.dataObj}`, {
        duration: 200,
        smooth: true,
        offset: 0,
      });
    }, 1000);
  }, [location?.state?.dataObj]);
  return (
    <>
   
      <Element name="/home">
        <Navbar />
      </Element>
      <Hero />
      <Element name="/services">
        <Services />
      </Element>
      <AboutSection />
      <CaseStudy />
      <OurProcess />
      <AdvanceFeatures showText={false}/>
      <Element name="/slider">
        <Slider />
      </Element>
      <Element name="/Our clients">
      <OurClients showText={false}/>
      </Element>
      <Events />
      <SparkAccordian home={true}/>
      <Footer />
    </>
  );
}
