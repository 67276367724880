import React, { useState } from "react";
import {
  Button,
  Card,
  Col,
  Container,
  Modal,
  Row,
  Form,
} from "react-bootstrap";
import CustomButton from "../components/Button";
import toast from "react-hot-toast";
import {
  createUserWithEmailAndPassword,
  fetchSignInMethodsForEmail,
} from "firebase/auth";
import { auth, database } from "../firebase";
import { handleAuthError } from "../utils/error";
import { doc, setDoc } from "firebase/firestore";
import { useAuth } from "../provider/authProvider";
const User = (props) => {
    const {logout} = useAuth()
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [manager, setManager] = useState("");
  const selectChangeHandler=(e)=>{
    setManager(e.target.value)
  }



  const handleUserAdd = async () => {
    try {
      if (!email.includes("@") || !password || !manager)
        return toast.error("Please enter your valid Email,Password and Manager");
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      );
      const user = userCredential?.user;
      const { email: userEmail, uid }=user
      await setDoc(doc(database, "users",uid), {
        uid:uid,
        Email:userEmail,
        Role: manager,
        isAdmin: false,
        isManager: true,
      });
      toast.success("User as a manager add successfully");
      // toast.success("Due to the addition of a Manager, Firebase has changed the session in the entire app. You will need to log in again.");
      
      setEmail("");
      setPassword("");
      props?.onHide();
      // await logout();

    } catch (error) {
      const errorMessage = handleAuthError(error,email);
      if (errorMessage) {
        return toast.error(errorMessage);
      }

      return "Error registering user. Please try again later.";
    }
  };

 

  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Add Manager
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group controlId="formBasicEmail">
            <Form.Label>Email address</Form.Label>
            <Form.Control
              type="email"
              placeholder="Enter email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </Form.Group>
          <Form.Group controlId="formBasicEmail">
            <Form.Label>Manager</Form.Label>
            <Form.Select
            onChange={(e)=>selectChangeHandler(e)}
            value={manager}
            name="manager"
            >
              <option value="">select</option>
              <option value="manager">Manager</option>
              <option value="no-manager">Not Manager</option>
              </Form.Select>
          </Form.Group>
          <Form.Group controlId="formBasicPassword">
            <Form.Label>Password</Form.Label>
            <Form.Control
              type="password"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <CustomButton
          style={{
            width: "40%",
          }}
          type="button"
          onClick={handleUserAdd}
        >
          Add Manager
        </CustomButton>
        <CustomButton
          style={{
            width: "30%",
          }}
          onClick={props?.onHide}
        >
          Close
        </CustomButton>
      </Modal.Footer>
    </Modal>
  );
};

export default User;
