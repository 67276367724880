import React, { useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const TextEditor = ({value, handleChange}) => {
    return <ReactQuill theme="snow" name="editor" value={value} onChange={handleChange} 
    style={{
        height: '80%',      
    }}
    />;

}

export default TextEditor
