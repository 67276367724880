import React from "react";
import Navbar from "../../components/Navbar/Navbar";
import careerImg from "../../../assets/Images/career.png";
import "./Career.css";
import useSingleJob from "../../../hooks/useSingleJob";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import Spinner from "../../components/spiner/spiner";
import emailjs from "@emailjs/browser";
const CareerDescription = () => {
  const { slug } = useParams();
  const navigate = useNavigate();

  const { job, loading } = useSingleJob(slug);

  const html = job?.editor;

  const applyNavigateHandler = () => {
    navigate("/apply-job", {
      state: { job },
    });
  };

  return (
    <div>
      <Navbar />
      <div
        // style={{
        //   marginTop: "5rem",
        // }}
        className="spark-insights-job-description"
      >
        <div className="flex flex-col items-center justify-center">
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
 <img src={careerImg} width="100" />
          </div>
          <h1 className="text-heading mb-2 text-4xl font-bold text-center">
            <span style={{ color: "rgb(27, 79, 146)" }}>
              {" "}
              Sparkix Technologies{" "}
            </span>
            <span> Job Description</span>
          </h1>
          <p className="text-lg text-center text-paragraph ">
            This is the job description. You can apply for the job here.{" "}
            <button
              onClick={() => applyNavigateHandler()}
              className="apply-job-btn"
            >
              Apply job
            </button>
          </p>
        </div>
        {loading ? (
          <Spinner />
        ) : job ? (
          <div className="description-page">
            <div className="container">
              <div className="row">
                <div className="col-md-6">
                  <div className="job-description">
                    <div dangerouslySetInnerHTML={{ __html: html }} />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="requirements">
                    <h2>Requirements</h2>
                    <ul>
                      <li>
                        <strong>Years of Experience:</strong> 1 Year
                      </li>
                      <li>
                        <strong>Education:</strong> {job ? job?.education : ""}
                      </li>
                      <li>
                        <strong>Career Level:</strong>{" "}
                        {job ? job?.careerLevel : ""}
                      </li>
                      <li>
                        <strong>Salary:</strong>{" "}
                        {job ? job?.salaryExpected : ""}
                      </li>
                      {/* <li>
                        <strong>Posted Date:</strong>{" "}
                        {job?.postDate
                          ? moment(job.postDate).format("LLL")
                          : ""}
                      </li> */}
                      <li>
                        <strong>Last Date to Apply:</strong>{" "}
                        {job?.applyDate
                          ? moment(job.applyDate).format("LLL")
                          : ""}
                      </li>
                    </ul>
                    <h2>Additional Information</h2>
                    <ul>
                      <li>
                        <strong>Industry:</strong> Information Technology Jobs
                      </li>
                      <li>
                        <strong>Functional Area:</strong>{" "}
                        {job ? job.profession : ""}
                      </li>
                      <li>
                        <strong>Total Positions:</strong> {" "}
                        {job ? job?.totalPositions : ""}
                      </li>
                      <li>
                        <strong>Job Type:</strong> Full Time Jobs
                      </li>
                      <li>
                        <strong>Gender:</strong> No Preference
                      </li>
                      <li>
                        <strong>Age:</strong> 18 - 40 years
                      </li>
                    </ul>
                    <div
                    className="text-center"
                    >
                      <button
                       
                        onClick={() => applyNavigateHandler()}
                        className="apply-job-btn-two"
                      >
                        Apply job
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "10vh",
            }}
          >
            <div
              style={{
                fontSize: "1rem",
                fontWeight: "bold",
              }}
            >
              Not Found any Listed Jobs.
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default CareerDescription;
