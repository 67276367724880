import React, { useState } from "react";
import "../Frontend/Career/Career.css";

import Spinner from "../../components/spiner/Spiner";
import LoadMoreButton from "../../components/LoadMore.jsx";
import useResumes from "../../hooks/useresumes.jsx";
import ResumesCard from "../../components/resumes/Card.jsx";
import { Form } from "react-bootstrap";
const JobsList = () => {
  const { resumes, loading, fetchMoreJobs,totalResumes ,getAllResumes} = useResumes();
  const [professionType,setProfessionType] = useState("");

  const filteredResumes = resumes.filter(resume =>
    professionType === "" ||
    resume.professType?.toLowerCase().includes(professionType?.toLowerCase())
  );
const handleFetchMoreJobs = () => {
  setProfessionType("");
  fetchMoreJobs();
};

  return (
    <div>
      <div className="resumes-sparKix-career-page">
        <div className="sparKix-career-page-container">
          <div>
            <div className="search-container">
              <div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      fontSize: "1.4rem",
                      fontWeight: "bold",
                      color: "#284F8D",
                      borderRadius: "10px",
                      fontFamily: "Poppins",
                    }}
                  >
                    Resumes
                  </div>
                  <div className="row pt-1">
                    <div className="col-sm-12">
                      <div className="form-group">
                        <Form>
                        <Form.Control
                        type="search"
                        placeholder="Search resumes"
                        name="professionType"
                        value={professionType}
                        onChange={(e)=>setProfessionType(e.target.value)}
                        required
                        />
                   
                        </Form>
                         
                       
                      </div>
                    </div>
                  </div>
                </div>
                <div className="pt-3 pb-3"></div>

                {loading ? (
                  <div>
                    <Spinner />
                  </div>
                ) : filteredResumes?.length > 0 ? (
                  filteredResumes.map((resume, index) => (
                    <ResumesCard
                      key={index}
                      name={resume.name}
                      email={resume.email}
                      resumeLink={resume.resume}
                      date={resume.date}
                      coverLetter={resume?.coverLetter}
                      fileName={resume?.fileName}
                      professionType={resume?.professType}
                      index={resume.id}
                      getAllResumes={getAllResumes}
                    />
                  ))
                ) : (
                  <div style={{ textAlign: "center", fontWeight: "bold" }}>
                    No Resume List
                  </div>
                )}
              </div>

              <div
                style={{
                  float: "right",
                  marginBottom: "2rem",
                }}
              >
                {resumes?.length > 0 && resumes?.length < totalResumes&& (
                  <LoadMoreButton onClick={
                    handleFetchMoreJobs} />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default JobsList;
