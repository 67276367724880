// Navbar.js
import React from "react";
import { FaBars } from "react-icons/fa";
import { IoMdLogOut } from "react-icons/io";
import { useMediaQuery } from "react-responsive";
// import Hamburger from 'hamburger-react'
import { Twirl as Hamburger } from 'hamburger-react'

import "./Navbar.css";
import { Navbar } from "react-bootstrap";

const Nav = ({ toggleSidebar }) => {
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });
  const handleLogout = () => {
    // Implement logout functionality here
  };
  return (
    <Navbar className="spark-admin-navbar">
     
     {isTabletOrMobile&&<button className="spark-admin-toggle-button" onClick={toggleSidebar}>
        <Hamburger size={30} />
      </button>} <button className="spark-admin-navbar-toggle" onClick={toggleSidebar}>
        <img src={"/logo.png"} alt="Logo" className="spark-admin-logo" />
      </button>
    
    </Navbar>
  );
};

export default Nav;
