import React from "react";
import "../../pages/Frontend/Career/Career.css";
import {useParams } from "react-router-dom";
import moment from "moment";
import emailjs from "@emailjs/browser";
import Spinner from "../components/spiner/spiner";
import useSingleJob from "../../hooks/useSingleJob";
const JobDescription = () => {
  const { slug } = useParams();
  const { job, loading } = useSingleJob(slug);
  const html = job?.editor;
  return (
    <div>
      
      <div  style={{
        padding: '30px 20px',
      }}>
        <div className="flex flex-col items-center justify-center">
        
          <h1 className="text-heading  text-4xl font-bold text-center"
          style={{
           paddingBottom: '50px',
          }}
          >
            <span style={{ color: "rgb(27, 79, 146)" }}>
              {" "}
              Sparkix Technologies{" "}
            </span>
            <span> Job Description</span>
          </h1>
         
        </div>
        {loading ? (
          <Spinner />
        ) : job ? (
          <div className="description-page">
            <div className="container">
              <div className="row">
                <div className="col-md-6">
                  <div className="job-description">
                    <div dangerouslySetInnerHTML={{ __html: html }} />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="requirements">
                    <h2>Requirements</h2>
                    <ul className="requirements-ulAndList">
                      <li>
                        <strong>Years of Experience:</strong> 1 Year
                      </li>
                      <li>
                        <strong>Education:</strong> {job ? job?.education : ""}
                      </li>
                      <li>
                        <strong>Career Level:</strong>{" "}
                        {job ? job?.careerLevel : ""}
                      </li>
                      <li>
                        <strong>Salary:</strong>{" "}
                        {job ? job?.salaryExpected : ""}
                      </li>
                      {/* <li>
                        <strong>Posted Date:</strong>{" "}
                        {job?.postDate
                          ? moment(job.postDate).format("LLL")
                          : ""}
                      </li> */}
                      <li>
                        <strong>Last Date to Apply:</strong>{" "}
                        {job?.applyDate
                          ? moment(job.applyDate).format("LLL")
                          : ""}
                      </li>
                    </ul>
                    <h2>Additional Information</h2>
                    <ul>
                      <li>
                        <strong>Industry:</strong> Information Technology Jobs
                      </li>
                      <li>
                        <strong>Functional Area:</strong>{" "}
                        {job ?job.profession: ""}
                      </li>
                      <li>
                        <strong>Total Positions:</strong>{" "}
                        {job ? job?.totalPositions : ""}
                      </li>
                      <li>
                        <strong>Job Type:</strong> Full Time Jobs
                      </li>
                      <li>
                        <strong>Gender:</strong> No Preference
                      </li>
                      <li>
                        <strong>Age:</strong> 18 - 40 years
                      </li>
                    </ul>
                 <div
              
                 >
                  </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "10vh",
            }}
          >
            <div
              style={{
                fontSize: "1rem",
                fontWeight: "bold",
              }}
            >
              Not Found any Listed Jobs.
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default JobDescription;
