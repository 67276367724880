import { useState, useEffect } from "react";
import { database } from "../firebase";
import { doc, getDoc } from "firebase/firestore";

const useSingleJob = (jobId) => {
  const [job, setJob] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchJob = async () => {
      try {
        const jobDoc = await getDoc(doc(database, "jobs", jobId));
      
        if (jobDoc.exists()) {
          setJob(jobDoc.data());
          setLoading(false);
        } else {
          setError("Job not found");
          setLoading(false);
        }
      } catch (error) {
        setError(error.message);
        setLoading(false);
      }
    };

    fetchJob();
  }, [jobId]);

  return { job, loading, error };
};

export default useSingleJob;
