import React from "react";
import ResponsiveTable from "./table/table";
import useUsers from "../../hooks/useUsers";
import CustomButton from "../../components/Button";
import User from "../../model/User";
import { useAuth } from "../../provider/authProvider";
import Spinner from "../components/spiner/spiner";
import './user.css'
const Users = () => {
  const { users, loading, error } = useUsers();
  const { user } = useAuth();
  const [modalShow, setModalShow] = React.useState(false);
  const headers = ["Email", "Role"];
  return (
    <div className="spark-admin-user-container">
      {user?.isAdmin ? (
        <div>
          <User show={modalShow} onHide={() => setModalShow(false)} />

          <div
            className="user-btn"
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "10px",
            }}
          >
            <div
              style={{
                fontSize: "1.4rem",
                fontWeight: "bold",
                color: "#284F8D",
                borderRadius: "10px",
                fontFamily: "Poppins",
              }}
            >
              Mangers List
            </div>
            <CustomButton
              onClick={() => setModalShow(true)}
              style={{
                width: "150px",
                height: "auto",
                margin: "0px 10px",
              }}
            >
              Add User
            </CustomButton>
          </div>
          {loading ? (
                  <div>
                    <Spinner />
                  </div>
                ) : users?.length > 0 ? 
          <ResponsiveTable headers={headers} data={users} />:
          <div style={{ textAlign: "center", fontWeight: "bold" }}>
          Users Not Found
        </div>
          }
        </div>
      ) : (
        <div className="text-center pt-2 fs-5 fw-bold">You are not an Admin to manage Users</div>
      )}
    </div>
  );
};

export default Users;
