import React, { useRef } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "./CaseStudy.css";

// import img1 from "../../../assets/Images/CaseStudy1.PNG";
// import img2 from "../../../assets/Images/CaseStudy2.PNG";
import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useStateContext } from "../../../context/AppContext";
import { caseStudies } from "../../../utils/Data";

export default function CaseStudy() {
  const { light } = useStateContext();
  const swiperRef = useRef(null);

  const handlePrev = () => {
    if (swiperRef.current) {
      swiperRef.current.swiper.slidePrev();
    }
  };

  const handleNext = () => {
    if (swiperRef.current) {
      swiperRef.current.swiper.slideNext();
    }
  };

  return (
    <div className="casestudy-section" style={{ backgroundColor: light }}>
      <h1>Case Studies</h1>
      
      <div className="casestudy-head">
        <p>
          Discover how our IT solutions drive ROI and traffic for our clients
          worldwide.
        </p>
        <div>
          <FontAwesomeIcon
            className="cs-icon"
            icon={faArrowLeft}
            onClick={handlePrev}
          />
          <FontAwesomeIcon
            className="cs-icon"
            icon={faArrowRight}
            onClick={handleNext}
          />
        </div>
      </div>

      <div className="cs-slider">
        <Swiper
          ref={swiperRef}
          slidesPerView={"auto"}
          spaceBetween={30}
          navigation={{
            prevEl: ".cs-icon.fa-arrow-left",
            nextEl: ".cs-icon.fa-arrow-right",
          }}
          className="mySwiper"
        >
          {caseStudies?.map((t, i) => (
            <SwiperSlide key={i}>
              <div
                className="cs-content"
                style={{ backgroundColor: t.bgColor }}
              >
                <div className="cs-left">
                  <h5 style={{ color: t.textColor }}>{t.title}</h5>
                  <p style={{ color: t.textColor }}>{t.description}</p>
                </div>
                <div className="cs-right">
                  <img src={t.imgLink} alt="Loading..." />
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
}

// {/* <SwiperSlide>
//   <img src={img2} alt="Case Study 2" />
// </SwiperSlide>
// <SwiperSlide>
//   <img src={img1} alt="Case Study 3" />
// </SwiperSlide>
// <SwiperSlide>
//   <img src={img2} alt="Case Study 4" />
// </SwiperSlide> */}
