import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleDown,
  faArrowRight,
  faLayerGroup,
  faOutdent,
  faPeopleGroup,
} from "@fortawesome/free-solid-svg-icons";
import { Link, useNavigate } from "react-router-dom";
import { scroller } from "react-scroll";

import "./Navbar.css";
import Logo from "../../../assets/Logo/satesfylogo.png";
import LogoCircle from "../../../assets/Logo/circle-logo.png";
import { useStateContext } from "../../../context/AppContext";
import Headroom from "react-headroom";

export default function Navbar() {
  const { dark } = useStateContext();
  const navigate = useNavigate();
  const [isActive, setisActive] = useState(false);
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const [isSticky, setIsSticky] = useState(false);
  const handleClick = async(e) => {
    if (e === "services") {
      scroller.scrollTo("/services", {
        duration: 200,
        smooth: true,
        offset: 180,
      });
    }else if(e === "Our clients") {
      await navigate("/")
      scroller.scrollTo("/Our clients", {
        duration: 200,
        smooth: true,
        offset: -60,
      });
    }

  };


  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 768);
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      const isTop = window.scrollY > 100;
      if (isTop !== isSticky) {
        setIsSticky(isTop);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [isSticky]);

  return (
    <
    >

   
    <nav   className={`navbar navbar-expand-lg bg-light custom-navbar ${
      isSticky && !isSmallScreen ? "spark-website-sticky" : ""
    }`}>
      <div className="container-fluid">
        <div className="navbar-brand" onClick={() => navigate("/")}>
          <img src={Logo} alt="Loading"/>
        </div>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarScroll"
          aria-controls="navbarScroll"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarScroll">
          <ul className="navbar-nav ms-auto">
            {/* <li className="nav-item">
              <div className="nav-link">
                Color Options
                <input
                  value={dark}
                  onChange={(e) => setdark(e.target.value)}
                  placeholder="dark"
                />
                <input
                  value={light}
                  onChange={(e) => setlight(e.target.value)}
                  placeholder="light"
                />
              </div>
            </li> */}
            <li className="nav-item">
              <div className="nav-link" onClick={() => navigate("/")}>
                <span>Home</span>
              </div>
            </li>
            <li className="nav-item">
              <div className="nav-link" onClick={() => navigate("/careers")}>
                <span>Careers</span>
              </div>
            </li>
            <li className="nav-item">
              <div className="nav-link" onClick={() => handleClick("Our clients")}>
                <span>Our Clients</span>
              </div>
            </li>
            <li className="nav-item">
              <div className="nav-link" onClick={() => handleClick("services")}>
                <span
                  onClick={(e) => {
                    if (isSmallScreen) {
                      e.stopPropagation();
                    }
                    setisActive(!isActive);
                  }}
                >
                  Services{" "}
                  <FontAwesomeIcon
                    icon={faAngleDown}
                    className="nav-link-icon"
                  />
                </span>
              </div>

              <div className={`nav-popup1 shadow ${isActive ? "active" : ""}`}>
                <div className="popup1-head">
                  <img
                    src={LogoCircle}
                    alt="Loading"
                  />
                  <h5 className="nav-logo">Software Development Services.</h5>
                </div>
                <p className="popup1-desc">
                  Accelerate your tech projects with outsourced development.
                  Flexible engagement models, tailored to you.
                </p>
                <div className="popup1-hr" />
                <div className="container-fluid popup1-body">
                  <div className="row">
                    <div className="col-lg-5 col-md-5 col-sm-9 col-12 p-0">
                      <div className="popup1-sec1">
                        <h5 className="nav-logo">Engagement models.</h5>
                        <p className="popup1-text">
                          You can hire our software developers in different ways
                        </p>
                        <div className="popup1-box">
                          <FontAwesomeIcon
                            icon={faLayerGroup}
                            style={{ color: dark }}
                            className="popup1-box-icon"
                          />
                          <div>
                            <h6>Staff Augmentation</h6>
                            <p>Our software developers in your team.</p>
                          </div>
                        </div>
                        <div className="popup1-box">
                          <FontAwesomeIcon
                            icon={faPeopleGroup}
                            style={{ color: dark }}
                            className="popup1-box-icon"
                          />
                          <div>
                            <h6>Dedicated Teams</h6>
                            <p>Our software teams in your organization.</p>
                          </div>
                        </div>
                        <div className="popup1-box">
                          <FontAwesomeIcon
                            icon={faOutdent}
                            style={{ color: dark }}
                            className="popup1-box-icon"
                          />
                          <div>
                            <h6>Software Outsourcing</h6>
                            <p>Our PM and software teams building for you.</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-5 col-md-5 col-sm-12 col-12 p-0">
                      <div className="popup1-sec2">
                        <h5 className="nav-logo">Solutions.</h5>
                        <p
                        className="services-popup-navbar-link"
                        style={{
                          cursor: 'pointer'
                        }}
                        onClick={() => navigate("/ai-chatbot-and-saas-solutions")}
                        >
                        AI Chatbot Development and SaaS Solutions
                       </p>
                        <p>Website Development</p>
                        <p>
                          Mobile App Development ( Android and iOS- Hybrid Apps)
                        </p>
                        <p>Machine Learning and deep learning Solutions</p>
                        <p>Computer vision and NLP solutions</p>
                        <p>QA Testing & Automation</p>
                        <p>Logo Designing</p>
                        <p>UI/UX Designing</p>
                      </div>
                    </div>
                    <div className="col-lg-2 col-md-2 col-sm-3 col-12 p-0">
                      <div className="popup1-sec2">
                        <h5 className="nav-logo">Technologies.</h5>
                        <p>React</p>
                        <p>Node.js</p>
                        <p>Python</p>
                        <p>.Net</p>
                        <p>Java</p>
                        <p>React Native</p>
                        <p>Ruby</p>
                        <p>Php</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </li>
            <li className="nav-item">
              <div className="nav-link" onClick={() => navigate("/about-us")}>
                <span>About Us</span>
              </div>
            </li>

            <button
              className="nav-button"
              onClick={() => navigate("/schedule-call")}
              style={{ backgroundColor: dark }}
            >
              Schedule a Call <FontAwesomeIcon icon={faArrowRight} />
            </button>
          </ul>
        </div>
      </div>
    </nav>
    </>
  );
}
