// LoadMoreButton.js

import React from 'react';
import './loadmore.css'; // Importing CSS file

const LoadMoreButton = ({ onClick }) => {
  return (
    <button className="load-more-button" onClick={onClick}>
      See More
    </button>
  );
}

export default LoadMoreButton;
