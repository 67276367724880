import React from "react";
import Slider from "react-slick";
import { AiOutlineArrowLeft, AiOutlineArrowRight } from "react-icons/ai";

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div onClick={onClick} className={`spark-arrow ${className}`}>
      <AiOutlineArrowRight class="spark-arrows" style={{ color: "white" }} />
    </div>
  );
}
const SamplePrevArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div onClick={onClick} className={`spark-arrow ${className}`}>
      <AiOutlineArrowLeft class="spark-arrows" style={{ color: "white" }} />
    </div>
  );
};

function OurClientsSlider({ OurSlides, aiSliderImages = false }) {
  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    initialSlide: 0,
    autoplay: true,
    autoplaySpeed: 2000,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
          dots: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: false,
        },
      },
    ],
  };
  return (
    <div
      className="slider-container"
      style={{
        padding: "0rem .5rem",
      }}
    >
      <Slider {...settings}>
        {OurSlides.map((item,index) => (
          <div className="col-lg-3 col-md-4 col-xs-6">
            <div className="client-logo">
              <img
                src={item.image}
                className="spark-slider-images-slick img-fluid"
                alt=""
                style={{
                  minWidth: aiSliderImages ? "150px" : index===1||index===3||index===2 ?"230px":'',
                  width: "100%",
                  height: "auto",
                  objectFit: "cover",
                  borderRadius:  "10px",
              
                }}
              />
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
}

export default OurClientsSlider;
