import { createContext, useContext, useState } from "react";
// import axios from "axios";
const AuthContext = createContext();

// eslint-disable-next-line react/prop-types
export default function AppContext({ children }) {
  const [dark, setdark] = useState("#1B4F92");
  const [light, setlight] = useState("#0077B6");

  return (
    <AuthContext.Provider
      value={{
        dark,
        setdark,
        light,
        setlight,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export const useStateContext = () => useContext(AuthContext);
