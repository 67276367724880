
const demoProxyServerUrl = 'https://sparkixtech.pythonanywhere.com/chat';
export const createStreamAdapter = (sessId) => ({
  streamText: async (query, observer) => {
    try {
      const body = { query, sess_id: sessId };
      const response = await fetch(demoProxyServerUrl, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(body),
      });
      const result = await response.text();
      if (response.status !== 200) {
        observer.error(new Error('Failed to connect to the server'));
        return;
      }
      if (result) {
        observer.next(result);
      } else {
        observer.error(new Error('Invalid response format'));
      }
      observer.complete();
    } catch (error) {
      observer.error(error);
    }
  },
});
