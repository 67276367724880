import React from "react";
import Accordion from "react-bootstrap/Accordion";
import "./faq.css";

const skillsData = [
    {
      question: "What types of AI solutions does your company offer?",
      answer:
        "We specialize in creating customized AI solutions across multiple platforms including web, mobile, chatbot, and desktop applications. Our services are designed to integrate seamlessly with existing systems, enhancing functionality and user experience.",
    },
    {
      question: "How does your AI development process work?",
      answer:
        "Our development process begins with a thorough consultation to understand your specific needs and objectives. We then design a prototype, which upon approval, moves to development and testing phases to ensure functionality and reliability before deployment.",
    },
    {
      question: "Can your AI solutions integrate with my existing infrastructure?",
      answer:
        "Absolutely. Our AI solutions are built to be compatible with existing infrastructures. We focus on ensuring seamless integration without disrupting your current operations, thus enhancing your systems with intelligent AI capabilities without the need for major overhauls.",
    },
    {
      question: "What makes your AI solutions unique?",
      answer:
        "Our AI solutions are custom-built to meet the specific demands of your business, leveraging cutting-edge technologies like machine learning, natural language processing, and real-time data processing to create solutions that not only meet but exceed your expectations.",
    },
    {
      question: "How do you ensure the privacy and security of the AI solutions?",
      answer:
        "Security and privacy are our top priorities. We adhere to best practices in data security and comply with relevant regulations to protect your information and ensure that your data is handled securely throughout every stage of the development process.",
    },
    {
      question: "What support do you offer post-deployment?",
      answer:
        "We offer free post launch support for 2 months in case of any bugs/issues. Later on, we can sign new terms & conditions to offer you comprehensive ongoing support and maintenance services to ensure your AI solution continues to operate efficiently post-deployment. Our support includes troubleshooting, updates, and optimizations to improve functionality and adapt to evolving business needs.",
    },
    {
      question: "How much does it cost to develop a custom AI solution?",
      answer:
        "The cost of developing a custom AI solution varies depending on the complexity of the project, the technologies involved, and the specific requirements of your business. We provide a detailed quote after the initial consultation and requirements analysis.",
    },
  ];
function SparkAccordian({home=false}) {
  return (
    <div className="spark-tech-faq">
      <h1
      className="spark-tech-faq-heading"
        style={{
          color: home?'black':"var(--primary-color)",
          fontSize: "40px",
          fontWeight:home?"600"
          : "700",
          textAlign: "center",
          marginTop:"10px",
          marginBottom: "5rem",
        }}
      >
        FAQS:
      </h1>
      <Accordion>
        {skillsData.map((skill, index) => (
          <Accordion.Item key={index} eventKey={index.toString()} className="spark-accordion-item">
            <Accordion.Header className="spark-accordion-heading">{skill.question}</Accordion.Header>
            <Accordion.Body className="spark-accordion-body">
              {skill.answer}
            </Accordion.Body>
          </Accordion.Item>
        ))}
      </Accordion>
    </div>
  );
}

export default SparkAccordian;
