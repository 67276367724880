import React, { useState, useMemo, useEffect } from "react";
import TextEditor from "../../components/TextEditor";
import CustomSelect from "../../components/Select";
import "./AddJobs.css";
import {
  CareerLevel,
  EducationLevels,
  Experience,
  JobType,
  Location,
  Profession,
  SalaryExpected,
  TotalPositions,
} from "../../utils/Const";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import toast from "react-hot-toast";
import { useParams } from "react-router-dom";
import useSingleJob from "../../hooks/useSingleJob";
import { database } from "../../firebase";
import Spinner from "../components/spiner/spiner";

const EditJob = () => {
  const { slug } = useParams();
  const { job, loading } = useSingleJob(slug);
  const memoizedJob = useMemo(() => job, [job]);
const [isupdateLoading,setisUpdateLoading]=useState(false);

  const [profession, setProfession] = useState("");
  const [location, setLocation] = useState("");
  const [salaryExpected, setSalaryExpected] = useState("");
  const [yearsOfExperience, setYearsOfExperience] = useState("");
  const [education, setEducation] = useState("");
  const [careerLevel, setCareerLevel] = useState("");
  const [totalPositions, setTotalPositions] = useState("");
  const [jobType, setJobType] = useState("");
  const [editor, setEditor] = useState("");
  const [postDate, setPostDate] = useState("");
  const [applyDate, setApplyDate] = useState("");

  useEffect(() => {
    if (memoizedJob && slug) {
      setProfession(memoizedJob?.profession || "");
      setLocation(memoizedJob?.location || "");
      setSalaryExpected(memoizedJob?.salaryExpected || "");
      setYearsOfExperience(memoizedJob?.yearsOfExperience || "");
      setEducation(memoizedJob?.education || "");
      setCareerLevel(memoizedJob?.careerLevel || "");
      setTotalPositions(memoizedJob?.totalPositions || "");
      setJobType(memoizedJob?.jobType || "");
      setEditor(memoizedJob?.editor || "");
      setPostDate(memoizedJob?.postDate || "");
      setApplyDate(memoizedJob?.applyDate || "");
    }
  }, [memoizedJob, slug]);

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      if (!slug)
        return toast.error("you must Provide a job id for update job.");
      setisUpdateLoading(true);
      let data = {
        profession,
        location,
        salaryExpected,
        yearsOfExperience,
        education,
        careerLevel,
        totalPositions,
        jobType,
        editor,
        postDate,
        applyDate,
      };
      await updateDoc(doc(database, "jobs", slug), data);
      toast.success("job updated successfully");
      setisUpdateLoading(false);
    } catch (error) {
        setisUpdateLoading(false);
      toast.error(error.message);
      console.log(error.message);
    }
  };

  if (loading) {
    return <Spinner />;
  }

 

  return (
    <div>
      <div className="sparkadmin-dashboard-job-admin-container">
        <div className="flex flex-col items-center justify-center">
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
          </div>
          <h1 className="text-heading mb-2 text-4xl font-bold text-center">
            Edit
            <span style={{ color: "rgb(27, 79, 146)" }}>
              {" "}
              Sparkix Technologies{" "}
            </span>
            <span> Job</span>
          </h1>
          <p className="text-lg text-center text-paragraph ">
            Admins alone have the power to Edit jobs here, ensuring smooth
            operation.
          </p>
        </div>
        <div className="container mt-5">
          <div className="">
            <div className="col-md-12">
              <div className="card">
                <div className="card-header"> Edit job details here.</div>
                <div className="card-body">
                  <form onSubmit={handleSubmit}>
                    <div className="row pt-1">
                      <div className="col-sm-12 col-md-6">
                        <div className="form-group">
                          <CustomSelect
                            isFormData={false}
                            name="Profession"
                            selectName="profession"
                            options={Profession}
                            value={profession}
                            setFormData={setProfession}
                          />
                        </div>
                      </div>
                      <div className="col-sm-12 col-md-6">
                        <div className="form-group">
                          <CustomSelect
                            isFormData={false}
                            name="Location"
                            selectName="location"
                            value={location}
                            setFormData={setLocation}
                            options={Location}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row pt-1">
                      <div className="col-sm-12 col-md-6">
                        <div className="form-group">
                          <CustomSelect
                            isFormData={false}
                            name="Salary Expected"
                            selectName="salaryExpected"
                            value={salaryExpected}
                            setFormData={setSalaryExpected}
                            options={SalaryExpected}
                          />
                        </div>
                      </div>
                      <div className="col-sm-12 col-md-6">
                        <div className="form-group">
                          <div className="form-group">
                            <CustomSelect
                              isFormData={false}
                              selectName="yearsOfExperience"
                              value={yearsOfExperience}
                              name="Years Of Experience"
                              options={Experience}
                              setFormData={setYearsOfExperience}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row pt-1">
                      <div className="col-sm-12 col-md-6">
                        <div className="form-group">
                          <CustomSelect
                            isFormData={false}
                            selectName="education"
                            value={education}
                            name="Education"
                            options={EducationLevels}
                            setFormData={setEducation}
                          />
                        </div>
                      </div>

                      <div className="col-sm-12 col-md-6">
                        <div className="form-group">
                          <CustomSelect
                            isFormData={false}
                            selectName="careerLevel"
                            name="Career Level"
                            options={CareerLevel}
                            value={careerLevel}
                            setFormData={setCareerLevel}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="row pt-1">
                      <div className="col-sm-12 col-md-6">
                        <div className="form-group">
                          <CustomSelect
                            selectName="jobType"
                            name="Job Type"
                            value={jobType}
                            options={JobType}
                            isFormData={false}
                            setFormData={setJobType}
                          />
                        </div>
                      </div>
                      <div className="col-sm-12 col-md-6">
                        <div className="form-group">
                          <CustomSelect
                            selectName="totalPositions"
                            name="Total Positions"
                            value={totalPositions}
                            options={TotalPositions}
                            isFormData={false}
                            setFormData={setTotalPositions}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="row pt-1">
                      <div className="col-sm-12 col-md-6">
                        <div className="form-group">
                          <div className="select-heading">Posted Date</div>
                          <input
                            type="datetime-local"
                            placeholder="Bachelor's in (BSCS / BSIT / BSSE)"
                            className="form-control"
                            name="postDate"
                            value={postDate}
                            onChange={(e) => setPostDate(e.target.value)}
                            required
                          />
                        </div>
                      </div>
                      <div className="col-sm-12 col-md-6">
                        <div className="form-group">
                          <div className="select-heading">Last Date to Apply</div>
                          <input
                            type="datetime-local"
                            id="datetime"
                            placeholder=""
                            className="form-control"
                            name="applyDate"
                            value={applyDate}
                            onChange={(e) => setApplyDate(e.target.value)}
                            required
                          />
                        </div>
                      </div>
                    </div>
                    <div className="select-heading">Job Description</div>
                    <div
                      style={{
                        height: "400px",
                        width: "100%",
                      }}
                    >
                      <TextEditor
                        value={editor}
                        handleChange={(e) => setEditor(e)}
                      />
                    </div>
                    <div className="job-submit-container">
                      <button
                        type="submit"
                        className="job-submit-button btn btn-primary"
                      >
                        {isupdateLoading?"loading....":"Update Job"}
                        
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditJob;
