import React, { useEffect, useState } from "react";
import Particles, { initParticlesEngine } from "@tsparticles/react";
import { loadSlim } from "@tsparticles/slim";

import "./Services.css";

import img1 from "../../../assets/Icons/chatbot.png";
import img2 from "../../../assets/Icons/webapp.png";
import img3 from "../../../assets/Icons/mobileAppdev.png";
import img4 from "../../../assets/Icons/ml.png";
import img5 from "../../../assets/Icons/computervision.png";
import img6 from "../../../assets/Icons/qa.png";
import img7 from "../../../assets/Icons/logodesigning.png";
import img8 from "../../../assets/Icons/uiux.png";
import { useStateContext } from "../../../context/AppContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretRight } from "@fortawesome/free-solid-svg-icons";
import {
  ssCV,
  ssChatBot,
  ssLogo,
  ssML,
  ssMobApp,
  ssQA,
  ssUIUX,
  ssWebApp,
} from "../../../utils/Data";
import { Navigate, useNavigate } from "react-router-dom";

export default function Services() {
  const { dark, light } = useStateContext();
  const [init, setInit] = useState(false);
  const navigate = useNavigate()

  useEffect(() => {
    initParticlesEngine(async (engine) => {
      await loadSlim(engine);
    }).then(() => {
      setInit(true);
    });
  }, []);

  const particlesLoaded = (container) => {
    // console.log(container);
  };



  const NavigateHanlder = ()=>{
    navigate("/ai-chatbot-and-saas-solutions")
  }

  return (
    <div className="services-section">
      <div className="container-fluid">
        <div className="row">
          <div className="col-12 p-0">
            <h1>Our Services</h1>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-3 col-md-6 p-0" onClick={()=>NavigateHanlder()}>
            <div className="scene card1" style={{ background: light }}>
              <div className="services-section-card">
                <div class="flip-card-inner">
                  <div class="flip-card-front" style={{ background: light }}>
                    <img src={img1} alt="Loading" />
                    <div>
                      <h5>AI Chatbot Development and SaaS Solutions</h5>
                      <p>
                        Smart solutions, <br />
                        seamless experiences.
                      </p>
                    </div>
                  </div>
                  <div class="flip-card-back" style={{ background: dark }}>
                    <div className="f-card-ul">
                      {ssChatBot?.map((t, i) => (
                        <div key={i}>
                          <FontAwesomeIcon
                            icon={faCaretRight}
                            className="card-icon"
                          />
                          <span>{t}</span>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 p-0">
            <div className="scene card2" style={{ background: light }}>
              <div className="services-section-card">
                <div class="flip-card-inner">
                  <div class="flip-card-front" style={{ background: light }}>
                    <img src={img2} alt="Loading" />
                    <div>
                      <h5>Website Development</h5>
                      <p>
                        Create websites <br />
                        you can adjust
                      </p>
                    </div>
                  </div>
                  <div class="flip-card-back" style={{ background: dark }}>
                    <div className="f-card-ul">
                      {ssWebApp?.map((t, i) => (
                        <div key={i}>
                          <FontAwesomeIcon
                            icon={faCaretRight}
                            className="card-icon"
                          />
                          <span>{t}</span>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 p-0">
            <div className="scene card2" style={{ background: light }}>
              <div className="services-section-card">
                <div class="flip-card-inner">
                  <div class="flip-card-front" style={{ background: light }}>
                    <img src={img3} alt="Loading" />
                    <div>
                      <h5>
                        Mobile App Development{" "}
                        <span>(Android and iOS- Hybrid Apps)</span>
                      </h5>
                      <p>
                        Develop impactful <br /> mobile apps
                      </p>
                    </div>
                  </div>
                  <div class="flip-card-back" style={{ background: dark }}>
                    <div className="f-card-ul">
                      {ssMobApp?.map((t, i) => (
                        <div key={i}>
                          <FontAwesomeIcon
                            icon={faCaretRight}
                            className="card-icon"
                          />
                          <span>{t}</span>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 p-0">
            <div className="scene card3" style={{ background: light }}>
              <div className="services-section-card">
                <div class="flip-card-inner">
                  <div class="flip-card-front" style={{ background: light }}>
                    <img src={img4} alt="Loading" />
                    <div>
                      <h5>Machine Learning and deep learning Solutions</h5>
                      <p>
                        Smart data solutions for <br /> powerful applications
                      </p>
                    </div>
                  </div>
                  <div class="flip-card-back" style={{ background: dark }}>
                    <div className="f-card-ul">
                      {ssML?.map((t, i) => (
                        <div key={i}>
                          <FontAwesomeIcon
                            icon={faCaretRight}
                            className="card-icon"
                          />
                          <span>{t}</span>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 p-0">
            <div className="scene card4" style={{ background: light }}>
              <div className="services-section-card">
                <div class="flip-card-inner">
                  <div class="flip-card-front" style={{ background: light }}>
                    <img src={img5} alt="Loading" />
                    <div>
                      <h5>Computer vision and NLP solutions</h5>
                      <p>
                        Tackle advanced vision <br /> and language tasks.
                      </p>
                    </div>
                  </div>
                  <div class="flip-card-back" style={{ background: dark }}>
                    <div className="f-card-ul">
                      {ssCV?.map((t, i) => (
                        <div key={i}>
                          <FontAwesomeIcon
                            icon={faCaretRight}
                            className="card-icon"
                          />
                          <span>{t}</span>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 p-0">
            <div className="scene card5" style={{ background: light }}>
              <div className="services-section-card">
                <div class="flip-card-inner">
                  <div class="flip-card-front" style={{ background: light }}>
                    <img src={img6} alt="Loading" />
                    <div>
                      <h5>QA Testing & Automation</h5>
                      <p>
                        Automate QA for <br /> efficient testing.
                      </p>
                    </div>
                  </div>
                  <div class="flip-card-back" style={{ background: dark }}>
                    <div className="f-card-ul">
                      {ssQA?.map((t, i) => (
                        <div key={i}>
                          <FontAwesomeIcon
                            icon={faCaretRight}
                            className="card-icon"
                          />
                          <span>{t}</span>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 p-0">
            <div className="scene card5" style={{ background: light }}>
              <div className="services-section-card">
                <div class="flip-card-inner">
                  <div class="flip-card-front" style={{ background: light }}>
                    <img src={img7} alt="Loading" />
                    <div>
                      <h5>Logo Designing</h5>
                      <p>
                        Craft memorable logos <br /> with creativity
                      </p>
                    </div>
                  </div>
                  <div class="flip-card-back" style={{ background: dark }}>
                    <div className="f-card-ul">
                      {ssLogo?.map((t, i) => (
                        <div key={i}>
                          <FontAwesomeIcon
                            icon={faCaretRight}
                            className="card-icon"
                          />
                          <span>{t}</span>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 p-0">
            <div className="scene card6" style={{ background: light }}>
              <div className="services-section-card">
                <div class="flip-card-inner">
                  <div class="flip-card-front" style={{ background: light }}>
                    <img src={img8} alt="Loading" />
                    <div>
                      <h5>UI/UX Designing</h5>
                      <p>
                        Craft designs for <br /> great user experiences.
                      </p>
                    </div>
                  </div>
                  <div class="flip-card-back" style={{ background: dark }}>
                    <div className="f-card-ul">
                      {ssUIUX?.map((t, i) => (
                        <div key={i}>
                          <FontAwesomeIcon
                            icon={faCaretRight}
                            className="card-icon"
                          />
                          <span>{t}</span>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {init && (
        <Particles
          id="tsparticles"
          particlesLoaded={particlesLoaded}
          options={{
            background: {
              color: {
                value: "#fff",
              },
            },
            fpsLimit: 120,
            interactivity: {
              events: {
                onClick: {
                  enable: true,
                  mode: "push",
                },
                onHover: {
                  enable: true,
                  mode: "repulse",
                },
                resize: true,
              },
              modes: {
                push: {
                  quantity: 4,
                },
                repulse: {
                  distance: 200,
                  duration: 0.4,
                },
              },
            },
            particles: {
              color: {
                value: dark,
              },
              links: {
                color: dark,
                distance: 150,
                enable: true,
                opacity: 0.5,
                width: 1,
              },
              move: {
                direction: "none",
                enable: true,
                outModes: {
                  default: "bounce",
                },
                random: false,
                speed: 6,
                straight: false,
              },
              number: {
                density: {
                  enable: true,
                  area: 800,
                },
                value: 80,
              },
              opacity: {
                value: 0.5,
              },
              shape: {
                type: "circle",
              },
              size: {
                value: { min: 1, max: 5 },
              },
              collisions: { enable: false }, // new property to control length of particles not confirm is it will work or not
            },
            detectRetina: true,
            fullScreen: { zIndex: -1 },
            style: {
              position: "absolute",
            },
          }}
        />
      )}
    </div>
  );
}
