import React from "react";
import "./Industries.css";
import {
  faBaseball,
  faCar,
  faCartShopping,
  faGlobe,
  faHospital,
  faHouseUser,
  faIndustry,
  faPersonBiking,
  faSuitcase,
  faTruckMoving,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useStateContext } from "../../../context/AppContext";
export default function Industries() {
  const { dark } = useStateContext();

  const data = [
    {
      title: "Healthcare",
      icon: faHospital,
      iconColor: "#7CAF78",
    },
    {
      title: "Manufacturing",
      icon: faIndustry,
      iconColor: "#000",
    },
    {
      title: "Real Estate",
      icon: faHouseUser,
      iconColor: "#C5DCFD",
    },
    {
      title: "Food Delivery",
      icon: faPersonBiking,
      iconColor: "#F7524B",
    },
    {
      title: "Transportation",
      icon: faTruckMoving,
      iconColor: "#333333",
    },
    {
      title: "Automotive",
      icon: faCar,
      iconColor: "#FF6F52",
    },
    {
      title: "Sports & Fitness",
      icon: faBaseball,
      iconColor: "#FFA000",
    },
    {
      title: "Travel",
      icon: faSuitcase,
      iconColor: "#FF8F00",
    },
    {
      title: "Social media",
      icon: faGlobe,
      iconColor: "#B3404A",
    },
    {
      title: "E-commerce",
      icon: faCartShopping,
      iconColor: "#7C7C7C",
    },
  ];
  return (
    <div className="industries-main">
      <h1>
        INDUSTRIES THAT PROSPER ON OUR{" "}
        <span style={{ color: dark }}>LINES OF CODE</span>
      </h1>
      <div className="container-fluid">
        <div className="row">
          {data?.map((t, i) => (
            <div className="col-lg-3 col-md-4 col-sm-6 col-12" key={i}>
              <div className="indus-card">
                <FontAwesomeIcon
                  icon={t.icon}
                  className="indus-card-icon"
                  style={{ color: `${t.iconColor}` }}
                />
                <h5>{t.title}</h5>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
