import React from 'react'
import './spiner.css'
const Spinner = () => {
  return (
    <div className='spark-spinner'>
        <div className="spinner"></div>
    </div>
  )
}

export default Spinner