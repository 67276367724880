import { useState, useEffect } from "react";
import { collection, query, onSnapshot, limit, startAfter, getDocs, orderBy } from "firebase/firestore";
import { database } from "../firebase";

const useJobs = () => {
  const [jobs, setJobs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [lastDocument, setLastDocument] = useState(null);
  const [totalJobs, setTotalJobs] = useState(0);

  useEffect(() => {
    (async () => {
      try {
        const q = query(collection(database, "jobs"), orderBy("postDate", "desc"), limit(3));
        const unsubscribe = onSnapshot(q, async(querySnapshot) => {
          const totalQuery = query(collection(database, "jobs"));
          const totalSnapshot = await getDocs(totalQuery);
          setTotalJobs(totalSnapshot.size);
          const fetchedJobs = [];
          querySnapshot.forEach((doc) => {
            fetchedJobs.push(doc.data());
          });
          setJobs(fetchedJobs);
          setLoading(false);
          setLastDocument(querySnapshot.docs[querySnapshot.docs.length - 1]);
        },
        (error) => {
          setError(error);
          setLoading(false);
        }
        );
        return () => unsubscribe();
      } catch (error) {
        console.log(error);
      }
    })();
  }, []);

  const fetchMoreJobs = async () => {
    if (lastDocument) {
      const q = query(collection(database, "jobs"), orderBy("postDate", "desc"), startAfter(lastDocument), limit(3));
      const querySnapshot = await getDocs(q);
      const fetchedJobs = [];
      querySnapshot.forEach((doc) => {
        fetchedJobs.push(doc.data());
      });
      setJobs((prevJobs) => [...prevJobs, ...fetchedJobs]);
      setLastDocument(querySnapshot.docs[querySnapshot.docs.length - 1]);
    }
  };

  return { jobs, loading, error, fetchMoreJobs, totalJobs };
};

export default useJobs;