// Sidebar.js
import React from "react";
import {
  FaClipboardList,
  FaCog,
  FaHome,
} from "react-icons/fa";
import "./Sidebar.css";
import { IoMdLogOut } from "react-icons/io";
import { Link } from "react-router-dom";
import { useAuth } from "../provider/authProvider";
import CustomButton from "./Button";
import { RiFilePdfFill } from "react-icons/ri";

// eslint-disable-next-line react/prop-types
const Sidebar = ({ isOpen, toggleSidebar }) => {
  const { logout } = useAuth();
  const Logout = async () => {
    await logout();
  };

  return (
    <div className={`sidebar ${isOpen ? "open" : ""}`}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          margin: "auto",
          height: "auto",
        }}
      >
        <div className="sidebar-toggle"></div>
      </div>
      <div className="flex-column-button-logout">
        <ul className="sidebar-nav">
          <Link to="/dashboard" className="side-bar-link-style">
            <li>
              <FaHome className="nav-icon" />
              Managers
            </li>
          </Link>
            <Link to="/dashboard/add-jobs" className="side-bar-link-style">
          <li>
              <FaClipboardList className="nav-icon" />
              Add Jobs
          </li>
            </Link>
            <Link to="/dashboard/jobs-list" className="side-bar-link-style">
          <li>
              <FaClipboardList className="nav-icon" />
              Jobs List
          </li>
            </Link>
            <Link  className="side-bar-link-style" to="/dashboard/resumes">
          <li>
              <RiFilePdfFill className="nav-icon" />
              Resumes
          </li>
            </Link>
            <Link className="side-bar-link-style" to="/dashboard/">
          <li>
              <FaCog className="nav-icon" />
              Settings
          </li>
            </Link>
        </ul>

        <div className="p-1">
          <CustomButton className="logout-button-bottom" onClick={Logout}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                margin: "auto",
                height: "auto",
              }}
            ><span>
                <span className="logout-text">Logout</span>
              </span>
              <IoMdLogOut className="logout-icon" />
            </div>
          </CustomButton>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
