import React, { useEffect, useState } from "react";
import Navbar from "../../components/Navbar/Navbar";
import "./Career.css";
import careerImg from "../../../assets/Images/career.png";
import CustomSelect from "../../components/Select/Select";
import { Location, Profession } from "../../../utils/Data";
import Card from "../../components/JobCard/Card";
import useJobs from "../../../hooks/jobs";
import Spinner from "../../components/spiner/spiner";
import { Pagination } from "react-bootstrap";
import LoadMoreButton from "./Loadmore";
import Footer from "../../components/Footer/Footer";

const Career = () => {
  const { jobs, loading, fetchMoreJobs, totalJobs } = useJobs();
  const [profession, setProfession] = useState("");
  const [location, setLocation] = useState("");
  const [postDateFilter, setPostDateFilter] = useState("all");
  const openPositionsCount = totalJobs;
  const formattedCount =
    openPositionsCount < 10 ? `0${openPositionsCount}` : openPositionsCount;
  const changeHandler = (e) => {
    setProfession(e.target.value);
  };
  const changeLocationHandler = (e) => {
    setLocation(e.target.value);
  };

  const checkPostDate = (postDate) => {
    const currentDate = new Date();
    const jobDate = new Date(postDate);
    const diffInDays = Math.ceil(
      (currentDate - jobDate) / (1000 * 60 * 60 * 24)
    );
    switch (postDateFilter) {
      case "recent":
        return diffInDays <= 1;
      case "last7days":
        return diffInDays <= 7;
      case "last30days":
        return diffInDays <= 30;
      default:
        return true;
    }
  };
  const filteredJobs = jobs.filter((job) => {
    const professionMatch = job.profession.toLowerCase().includes(profession?.toLowerCase());
    const locationMatch = job.location.toLowerCase().includes(location?.toLowerCase());
    const postDateMatch = checkPostDate(job.postDate);
    return professionMatch && locationMatch && postDateMatch;
  });
  const changePostDateFilter = (e) => {
    setPostDateFilter(e.target.value);
  };
 const fetchMoreJobsHandler=()=>{
  setProfession("")
  setLocation(""); 
  fetchMoreJobs();
 }
 useEffect(() => {
  window.scrollTo(0, 0);
}, []);
  return (
    <div>
      <Navbar />
      <div  
      className="sparKix-career-page">
        <div className="sparKix-career-page-container">
          <div className="flex flex-col items-center justify-center">
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img src={careerImg} width="100" />
            </div>
            <h1 className="text-heading mb-2 text-4xl font-bold text-center">
              <span style={{ color: "rgb(27, 79, 146)" }}>Explore Career</span>
              <span> Opportunities</span>
            </h1>
            <p className="text-lg text-center text-paragraph ">
              Join our team and start building a career that matters.
            </p>
          </div>
          <div>
            <div className="search-container">
              <div className="row">
                <div className="col-sm-12 col-md-6">
                  <div className="box">
                    <CustomSelect
                    
                      onChange={changeHandler}
                      value={profession}
                      name="Profession"
                      options={Profession}
                    />
                  </div>
                </div>

                <div className="col-sm-12 col-md-6">
                  <div className="box">
                    <CustomSelect
                      name="Location"
                      value={location}
                      onChange={changeLocationHandler}
                      options={Location}
                    />
                  </div>
                </div>
              </div>
              <div>
                <div className="pt-3 pb-3">
                  <div className="row">
                    <div className="col-sm-12 col-md-6 d-flex justify-content-start align-items-center">
                      Open-Position ({formattedCount})
                    </div>
                    <div className="col-sm-12 col-md-6 d-flex justify-content-sm-start justify-content-md-end">
                      <div>
                        <select
                          id="postDateFilter"
                          value={postDateFilter}
                          onChange={changePostDateFilter}
                          class="form-select form-select-sm"
                          aria-label="Small select example"
                          style={{
                            maxWidth: "120px",
                            minWidth: "200px",
                          }}
                        >
                          <option value="recent">Latest</option>
                          <option value="last7days">Last 7 Days</option>
                          <option value="last30days">Last 30 Days</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="pt-3 pb-3 d-none d-sm-block">
                  <div className="row">
                    <div className="col-sm-12 col-md-4 d-flex justify-content-start align-items-center">
                      Role
                    </div>
                    <div className="col-sm-12 col-md-4 d-flex align-items-center">
                      <div>Location</div>
                    </div>
                  </div>
                </div>

                {loading ? (
                  <div>
                    <Spinner />
                  </div>
                ) : filteredJobs?.length > 0 ? (
                  filteredJobs.map((job, index) => (
                    <Card
                      key={index}
                      title={job.profession}
                      salary={job.salaryExpected}
                      datePosted={job.jobList}
                      type={job?.location}
                      applyDate={job.applyDate}
                      postDate={job.postDate}
                      index={job.id}
                    />
                  ))
                ) : (
                  <div style={{ textAlign: "center", fontWeight: "bold" }}>
                    No List job
                  </div>
                )}
              </div>

          <div
          style={{
            float: "right",
            marginBottom: "2rem",
          }}
          >
          {filteredJobs?.length >0&& jobs.length < totalJobs && <LoadMoreButton  onClick={fetchMoreJobsHandler}/>}
          </div>
            </div>
          </div>
        </div>
      </div>
      <Footer/>
    </div>
  );
};

export default Career;
