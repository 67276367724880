// import React from "react";
// import "./Select.css";
// import {Form} from 'react-bootstrap'
// const CustomSelect = ({ name, options,onChange,value,selectName }) => {

//   return (
// <div className="job-select">
//   <div className="select-heading">{name}</div>
//       <Form.Select
// style={{
//   height: "auto",
//   width: "100%",
// }}
// onChange={onChange}
//   value={value}
//   name={selectName}

//       >
//         <option>select {name}</option>
//         {options?.map((option) => (
//           <option key={option.value} value={option.value}>
//             {option.label}
//           </option>
//         ))}
//         </Form.Select>

//     </div>
//   );
// };

// export default CustomSelect;
import React, { useState } from "react";
import { Form } from "react-bootstrap";
import "./Select.css";
function CustomSelect({
  name,
  isFormData,
  options,
  setFormData,
  formData,
  value,
  selectName,
}) {
  const [filteredOptions, setFilteredOptions] = useState([]);
  const handleInputChange = (event) => {
    if (event.target) {
      const { name, value } = event.target;
      if (isFormData) {
        setFormData({ ...formData, [name]: value });
      } else {
        setFormData(value);
      }
    }
    if (options.length > 0) {
      const filteredSuggestions = options?.filter((suggestion) => {
        if (typeof suggestion.label === "string") {
          return suggestion.label.toLowerCase().includes(value?.toLowerCase());
        }
        return false;
      });
      setFilteredOptions(filteredSuggestions);
    } else {
      setFilteredOptions([]);
    }
  };

  const handleSuggestionClick = (suggestion, selectName) => {
    const updatedFormData = { ...formData };
    updatedFormData[selectName] = suggestion.label;
    if (isFormData) {
      setFormData(updatedFormData);
    } else {
      setFormData(suggestion.label);
    }
    setFilteredOptions([]);
  };
  return (
    <div className="autocomplete-wrapper">
      <div className="job-select">
        <div className="select-heading">{name}</div>
        <Form.Control
        required
          style={{
            height: "auto",
            width: "100%",
          }}
          value={value}
          name={selectName}
          type="text"
          onChange={handleInputChange}
          aria-autocomplete="list"
          aria-controls="autocomplete-list"
        />
        {filteredOptions.length > 0 && (
          <ul
            id="autocomplete-list"
            className="suggestions-list"
            role="listbox"
          >
            {filteredOptions.map((suggestion, index) => (
              <li
                key={index}
                onClick={() => handleSuggestionClick(suggestion, selectName)}
                role="option"
              >
                {suggestion.label}
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  );
}

export default CustomSelect;
