import React, { useState } from "react";

import "./Slider.css";
import icon1 from "../../../assets/Icons/icon1.png";
import icon2 from "../../../assets/Icons/icon2.png";
import icon3 from "../../../assets/Icons/icon3.png";
import icon4 from "../../../assets/Icons/icon4.png";
// import icon5 from "../../../assets/Icons/icon5.png";
import img1 from "../../../assets/Images/Education.png";
import img2 from "../../../assets/Images/Healthcare.png";
import img3 from "../../../assets/Images/FIntech.png";
import img4 from "../../../assets/Images/E-commerce.png";
// import img5 from "../../../assets/Images/Automotive.png";
import img6 from "../../../assets/Images/realestate.jpg";
import { useStateContext } from "../../../context/AppContext";

export default function Slider() {
  const { dark } = useStateContext();
  const [loadedImages, setLoadedImages] = useState([]);

  const handleImageLoad = (index) => {
    setLoadedImages((prevState) => [...prevState, index]);
  };

  const isImageLoaded = (index) => loadedImages.includes(index);

  const [data, setdata] = useState([
    {
      isActive: true,
      imgLink: icon2,
      title: "Healthcare",
      sImage: img2,
      sTitle: "Healthcare Redefined Through Tech-Driven Solutions",
      sText:
        "Achieve a big-picture view of patient care and medicinal management with the help of technology in healthcare. Through software applications and next-gen IT solutions, we bring clarity to decision-making and open doors to real-time telemedicine ease.",
      sFooter: "Dive into future-ready health and wellness",
    },
    {
      isActive: false,
      imgLink: icon1,
      title: "EdTech",
      sImage: img1,
      sTitle: "Pave The Way for Smart Education with EdTech",
      sText:
        "To streamline the hefty management of educational data and teacher-student interaction, we use technologies like VR/AR, AI, and Blockchain to develop seamless systems, virtual classrooms, and tools for restriction-free eLearning and better results.",
      sFooter: "Learn more about our EdTech services",
    },
    {
      isActive: false,
      imgLink: icon4,
      title: "E-Commerce",
      sImage: img4,
      sTitle: "Optimized & Interactive eCommerce Experiences",
      sText:
        "From swift web pages to user-friendly mobile apps that generate more sales, eCommerce is experiencing rapid change in today’s world. Our developers shape immersive shopping experiences along with maintaining user data and interfaces for your business needs.",
      sFooter: "Change the way you manage your online business",
    },
    {
      isActive: false,
      imgLink: icon3,
      title: "Social Media",
      sImage: img3,
      sTitle: "Social Media Mastery for Enhanced Business Impact",
      sText:
        "Elevate your brand with our expert social media strategies. We specialize in connecting businesses with their audiences, boosting brand visibility, and fostering growth in the dynamic world of digital communication.",
      sFooter: "Embrace the future of the financial world",
    },
    {
      isActive: false,
      imgLink: icon3,
      title: "Real Estate Technology",
      sImage: img6,
      sTitle: "Cutting-Edge Technology Solutions for Success",
      sText:
        "Experience the future of real estate with our innovative technology solutions. From streamlined property management to immersive virtual tours, we leverage advanced technologies to redefine the real estate landscape. Elevate your business with our tailored tech-driven approach.",
      sFooter: "Make gaming ideas secure and advance",
    },
  ]);

  const handleClick = (index) => {
    const newData = data.map((item, i) => ({
      ...item,
      isActive: i === index,
    }));

    setdata(newData);
  };
  return (
    <div className="slider-section">
      <div className="container-fluid">
        <h1 className="slider-title">
          Fuelling businesses across various sectors and global locations
        </h1>
        <div className="row">
          <div className="col-lg-4 col-md-12">
            <div className="slider-btn-wrap">
              {data?.map((t, i) => (
                <div
                  className="slider-btn"
                  onClick={() => handleClick(i)}
                  key={i}
                  style={{
                    background: t.isActive && dark,
                    color: t.isActive && "white",
                  }}
                >
                  <img src={t.imgLink} alt="" />
                  <div className="divider-vr" />
                  <h1 className="slider-btn-title">{t.title}</h1>
                </div>
              ))}
            </div>
          </div>
          <div className="col-lg-8 col-md-12">
            {data?.map(
              (t, i) =>
                t.isActive && (
                  <div className="slider-body" key={i}>
                    <div className="slider-info-wrap">
                      {!isImageLoaded(i) && (
                        <div className="siw-image siw-img-loader">
                          Loading...
                        </div>
                      )}
                      <img
                        className="siw-image"
                        src={t.sImage}
                        onLoad={() => handleImageLoad(i)}
                        style={{ display: isImageLoaded(i) ? "block" : "none" }}
                        alt=""
                      />
                      <div className="siw-info">
                        <p className="siw-title" style={{ color: dark }}>
                          {t.sTitle}
                        </p>
                        <p className="siw-desc">{t.sText}</p>
                        <a  style={{ background: dark }}>
                          {/* {t.sFooter}{" "}
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="18"
                            height="12"
                            viewBox="0 0 18 12"
                            fill="none"
                          >
                            <path
                              d="M11.4998 11.8331L10.3123 10.6664L14.1457 6.83305H0.666504V5.16638H14.1457L10.3332 1.33305L11.4998 0.166382L17.3332 5.99972L11.4998 11.8331Z"
                              fill="white"
                            ></path>
                          </svg> */}
                        </a>
                      </div>
                    </div>
                  </div>
                )
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
