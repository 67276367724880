import React, { useContext, useState } from "react";
import { Container, Row, Col, Card, Form, Button } from "react-bootstrap";
import "./Login.css";
import { auth, database } from "../../firebase";
import { doc, getDoc, getDocs, setDoc } from "firebase/firestore";
import toast from "react-hot-toast";
import { signInWithEmailAndPassword } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../provider/authProvider";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { token, login, logout, setUser, setToken } = useAuth();
  const handleLogin = async () => {
    try {
      setLoading(true);
      if (!email.includes("@") || !password)
        return toast.error("Please enter your valid Email and Password.");
      const res = await signInWithEmailAndPassword(auth, email, password);
      const { email: userEmail, uid, accessToken } = res?.user;
      const userDoc = await getDoc(doc(database, "users", uid));
      const userData = userDoc?.data();
      if (!userDoc.exists()) {
        await setDoc(doc(database, "users", uid), {
          uid,
          Email: userEmail,
          Role: "admin",
          isAdmin: true,
        });
        await login(accessToken, {
          uid,
          Email: userEmail,
          Role: "admin",
          isAdmin: true,
        });
        setUser({
          uid,
          Email: userEmail,
          Role: "admin",
          isAdmin: true,
        });
        navigate("/dashboard", { replace: true });
      } else {
        await login(accessToken, userData);
        setToken(accessToken);
        setUser(userData);
        if (userData.Role === "admin") {
          navigate("/dashboard", { replace: true });
        } else if (userData.Role === "manager") {
          navigate("/dashboard", { replace: true });
        }
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
      const cleanedErrorMessage = err?.message?.replace(/\bfirebase\b/gi, "");
      if (err.code === "auth/invalid-credential") {
        toast.error(
          "Invalid credential. Please check your email and password."
        );
      } else if (err.code === "auth/too-many-requests") {
        toast.error(
          "Access to this account has been temporarily disabled due to many failed login attempts. You can immediately restore it by resetting your password or you can try again later."
        );
      } else {
        toast.error(cleanedErrorMessage);
      }
    }

    // toast.error(err.message);
  };
  return (
    <div>
      <Container
        className=" "
        style={{ padding: "20px 0px", marginTop: "8rem" }}
      >
        <Row className="justify-content-center">
          <Col md={6}>
            <Card>
              <Card.Header className="text-center">
                <img
                  src="logo.png"
                  alt="Logo"
                  className="logo"
                  style={{
                    maxWidth: "200px",
                    width: "100%",
                  }}
                />
              </Card.Header>
              <Card.Body className="pt-2 pb-12">
                <Card.Title className="text-center spark-login-heading">
                  Login
                </Card.Title>
                <Form>
                  <Form.Group controlId="formBasicEmail">
                    <Form.Label>Email address</Form.Label>
                    <Form.Control
                      type="email"
                      placeholder="Enter email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </Form.Group>

                  <Form.Group controlId="formBasicPassword">
                    <Form.Label>Password</Form.Label>
                    <Form.Control
                      type="password"
                      placeholder="Password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                  </Form.Group>

                  <Button
                    style={{
                      width: "100%",
                    }}
                    variant="primary"
                    type="button"
                    onClick={handleLogin}
                    block
                    className="mt-3"
                    disabled={loading}
                  >
                    {loading ? "loading..." : "Login"}
                  </Button>
                </Form>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Login;
