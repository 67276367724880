// TableRow.jsx
import { deleteUser } from "firebase/auth";
import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import { FaTrash } from "react-icons/fa";
import { auth, database } from "../../../firebase";
import { deleteDoc, doc, updateDoc } from "firebase/firestore";
import toast from "react-hot-toast";
import Spinner from "../../components/spiner/spiner";

const TableRow = ({ data }) => {
  const [loading, setLoading] = useState(false);
  const handleRoleChange = async (uid, role) => {
    try {
      setLoading(true);
      if (role === "admin") {
        return toast.error(`You are not allowed to delete the admin `);
      }
      const userRef = doc(database, "users", uid);
      await updateDoc(userRef, {
        Role: role,
        isManager: false,
      });
      setLoading(false);
      toast.success("User role updated successfully.");
    } catch (error) {
      console.error("Error updating user role:", error.message);
      toast.error("Error updating user role. Please try again later.");
    }
  };

  if (loading) {
    return (
      <tr>
        <td colSpan="3" align="center">
          <Spinner />
        </td>
      </tr>
    );
  }

  return (
    <tbody>
      {data?.map((row, rowIndex) => (
        <tr key={rowIndex}>
          {/* <td>{row?.uid}</td> */}
          <td>{row?.Email}</td>
          <td>
            <Form.Select
              style={{
                minWidth: "100px",
                width: "100%",
              }}
              onChange={(e) => handleRoleChange(row.uid, e.target.value)}
              disabled={row.isAdmin === true}
              defaultValue={row.Role}
              value={row.Role}
            >
              {row?.isAdmin === true && (
                <option value="admin" disabled={row.Role === "admin"}>
                  Admin
                </option>
              )}
              <option value="manager" disabled={row.Role === "manager"}>
                Manager
              </option>
              <option value="no-manager" disabled={row.Role === "no-manager"}>
                Not Manager
              </option>
            </Form.Select>
          </td>
          {/* <td align="center">
            <FaTrash
              onClick={
                row.role === "admin"
                  ? toast.error("You cannot delete the Admin")
                  : () => onDeleteUser(row.uid, row.Role)
              }
              style={{ cursor: "pointer", color: "#284F8D" }}
            />
          </td> */}
        </tr>
      ))}
    </tbody>
  );
};

export default TableRow;
